import React, { Component } from 'react'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import socket from "../../../../Socket";
import storage from "../../../../Storage";
import C from "../../../../Constant";
import { defaultAvatar, encode, Game, Event } from "../../../../Helper";

class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            coin: this.props.coin ? this.props.coin : (storage.getKey('coin') !== null) ? storage.getKey('coin') : 'USDT'
        };
    }

    getDetail = (e) => {
        if (!this.props.byLink)
            e.preventDefault();

        if (this.props.clicked !== undefined)
            this.props.clicked(); // can close game modal

        const getFromUrl = () => {
            let id = this.props.params.pathname;
            return id.replace('/user/', '');
        }

        let id = this.props.id ? this.props.id : getFromUrl()
        Event.emit('single_game_modal_close');
        Event.emit('close_message_modal');
        socket.emit(C.USER_INFO, encode({ id: id, coin: this.state.coin, rate: null, game: 'all', first: true }));
        Event.emit('force_modal_user');
    };
    
    makeStars = (num) => {
        let stars = 5;
        let userLevel = stars - parseFloat(num);
        var g = [], o = [], f = [];
        for (var i = 0; i < userLevel; i++) {
            g.push(<span key={ i+1 } className="graysi"><i className="mdi mdi-star text-grey" /></span>);
        }
        for (var i = 0; i < num; i++) {
            o.push(<span key={ i+1 }><i className="mdi mdi-star text-success" /></span>);
        }
        f.push(o, g);
        return f;
    }

    render() {
        let { username, id, isWinner, cssClass, queue, menu, avatar, chat, level, byLink, time, vip_level } = this.props;
        if (byLink) {
            return '' + this.getDetail()
        }

        if (isWinner === true)
            cssClass = 'text-success';

        let link = <>
            {/* <Link to={'/user/' + id} onClick={e => this.getDetail(e)} className={cssClass}> */}
            <Link to={'#'} onClick={e => this.getDetail(e)} className={cssClass}>
                {username}
            </Link>
        </>;        //home/lastbets

        if (menu) {
            link = <>
                <Link to={'/user/' + id} onClick={e => this.getDetail(e)} className={'text-muted ng'}>
                    <p className="p-0 m-0">
                        <img src={avatar} alt="profile-user" className="thumb-md rounded-circle" />
                    </p>
                </Link>
            </>;
        }

        else if (queue) {
            let fixAvatar = (avatar !== undefined && avatar !== false && avatar !== "" && avatar !== null) ? avatar : defaultAvatar;
            link = <>
                <Link to={'/user/' + id} onClick={e => this.getDetail(e)} className={cssClass}>
                    <img src={fixAvatar} alt="user" className="rounded-circle thumb-xs mr-1 hidden-sm" />
                    {username}
                </Link>
            </>;
        }
        else {
            if (avatar) { // Chats
                link = <>
                    <div className='chat-user-avatar-wrapper'>
                        <Link to={'/user/' + id} onClick={e => this.getDetail(e)} className={cssClass}>
                            <div className="d-flex align-items-center gap-2">
                                <div className={`d-flex justify-content-center align-items-center bets-avatar-wrapper ${storage.getKey('name') == username ? 'my-avatar' : ''}`}>
                                    <img className={`thumb-lg rounded thumb-xs`} src={avatar} />
                                </div>
                                {username}
                            </div>
                        </Link>
                    </div>
                </>;
            }
            if (chat) {
                if (avatar === null) {
                    link = <>
                        <Link to={'/user/' + id} onClick={e => this.getDetail(e)} className={cssClass}>
                            <ul className={'ml-1 mt-0 d-flex'}>
                                <span>{username}</span>
                                <b className="user-level">
                                    {this.makeStars(level)}
                                </b>
                            </ul>
                        </Link>
                    </>;
                }
                else {
                    link = <>
                        <div className='chat-user-avatar-wrapper'>
                            <Link to={'/user/' + id} onClick={e => this.getDetail(e)} className={cssClass}>
                                <img src={avatar} alt="" className={`thumb-lg ${storage.getKey('name') == username ? 'my-avatar' : ''} `} />
                                { vip_level != 0 && username != "SystemBot" && <span className='vip-level-span'>V{vip_level}</span>}
                            </Link>
                        </div>
                    </>;
                }
            }
        }

        return link;
    }
}

Modal.propTypes = {
    coin: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin
});

export default connect(mapStateToProps, {})(Modal);
