import React from "react";
import { Row, Col, Button, Card, InputGroup, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import RangeCredit from "../../Components/Game/Addons/Range";
import AutoBet from "../../Components/Game/Addons/AutoBet";
import storage from "../../../Storage";
import { gameCoin } from "../../../actions/gameCoin";
import { setWallet } from "../../../actions/gameWallet";
import {
  Event,
  wait,
  getDefaultBetAmount,
  isValidNumber,
  isLogged,
  forceSatoshiFormat,
  funPlay,
  __,
  sendNotfication,
  defaultBetAmount,
} from "../../../Helper";

class Bet extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      bet: "manual",
      started: false,
      amount: defaultBetAmount(this.props.coin),
      credit: storage.getKey("credit"),
      inputDisabled: false,
      buttonText: "Bet",
      added: false,
      freezeAuto: false,
      balls: 0,
      formData: {
        rows: 9,
        risk: "low",
      },
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.wrapperRef = React.createRef();
    this.wrapperRef2 = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.outRef = React.createRef();
  }

  componentDidMount() {
    let { engine } = this.state;
    this._isMounted = true;
    this.props.gameCoin();
    engine.on("play", (data) => this.play(data));
    engine.on("busted", (data) => this.busted(data));
    engine.on("error", (data) => this.error(data));
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    let { engine } = this.state;
    engine.init = false;
    this._isMounted = false;
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.outRef && !this.outRef.current.contains(event.target)) {
      let value = parseFloat(this.state.amount);

      if (!isValidNumber(value)) {
        return this.setState({ amount: 0.1 });
      }
      value = __.clamp(getDefaultBetAmount(this.props.coin), 0.001, value);
      value = forceSatoshiFormat(value);
      if (!__.isNaN(value)) this.setState({ amount: value });
    }
  }

  error(data) {
    if (this._isMounted) {
      sendNotfication(data.message, "info", "top-center");

      if (data.code === "credit") this.props.setWallet(true, data.uid);

      this.setState({ disabled: false });

      if (this.state.started) {
        this.setState({ started: false, buttonText: "Start Auto Mode" });
      }
    }
  }

  play() {
    if (this._isMounted) {
      wait(500).then(() => {
        this.setState((prevState) => ({
          inputDisabled: false,
          balls: prevState.balls + 1,
        }));
      });
    }
  }

  busted(data) {
    if (this._isMounted) {
      let { engine, bet, started } = this.state;

      wait(100).then(() => {
        this.setState((prevState) => ({
          lock: false,
          playing: false,
          inputDisabled: false,
          balls: prevState.balls - 1,
        }));
        if (bet !== "auto") {
          engine.init = false;
        }
      });
    }
  }

  handleBet(e) {
    if (this._isMounted) {
      e.preventDefault();

      let { amount, bet, started } = this.state;

      // Check logged user
      if (!isLogged) {
        return Event.emit("showAuthModal", true);
      }

      this.setState({ inputDisabled: true });

      if (parseFloat(amount) <= 0) {
        this.setState({ amount: forceSatoshiFormat(0.001) });
      }

      this.placeBet();
    }
  }

  placeBet() {
    if (this._isMounted) {
      let { engine, amount } = this.state;

      wait(100).then(() => {
        engine.init = true;
        engine.risk = this.state.formData.risk;
        engine.amount = amount;
        engine.coin = this.props.coin;
        engine.start();
      });
    }
  }

  handleInputChange(event) {
    let target = event.target;
    let value = target.value;

    if (target.name === "amount") {
      this.state.engine.amount = value;
    }

    this.setState({ [target.name]: value });
  }

  handleChangeRows = (e, value) => {
    e.preventDefault();

    if (this.state.balls >= 1) return;

    for (var i = 0; i < this.wrapperRef.current.children.length; i++) {
      this.wrapperRef.current.children[i].classList.remove("active");
    }
    e.target.classList.add("active");
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        rows: value,
      },
    }));
    Event.emit("change_rows", value);
  };

  handleChangeRisk = (e, value) => {
    e.preventDefault();

    if (this.state.balls >= 1) return;

    for (var i = 0; i < this.wrapperRef2.current.children.length; i++) {
      this.wrapperRef2.current.children[i].classList.remove("active");
    }
    e.target.classList.add("active");
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        risk: value,
      },
    }));
    Event.emit("change_risk", value);
  };

  betMode = (type) => {
    this.setState({ bet: type });
  };

  setAmount = (value) => {
    if (!this.state.disabled) {
      if (!__.isNaN(value)) {
        this.setState({ amount: value });
        storage.setKey("lam", value);
        this.state.engine.amount = value;
      }
    }
  };

  render() {
    let { inputDisabled, amount, bet, started } = this.state;
    let { rows, risk } = this.state.formData;

    return (
      <>
        <Form className="w-100" onSubmit={(e) => this.handleBet(e)}>
          <Row>
            <Col xl={6} md={12} sm={12}>
              <InputGroup className="mb-1">
                <InputGroup.Text>
                  <img
                    src={
                      "/assets/images/" + __.toLower(this.props.coin) + ".png"
                    }
                    className={"mini-coin-7"}
                    alt=""
                  />
                  BET
                </InputGroup.Text>
                <Form.Control
                  ref={this.outRef}
                  disabled={inputDisabled}
                  type="text"
                  id="amount"
                  name="amount"
                  placeholder=".."
                  value={amount}
                  autoComplete={"off"}
                  className="form-control text-left"
                  onKeyUp={this.handleInputChange}
                  onChange={this.handleInputChange}
                ></Form.Control>
                <InputGroup.Text>
                  <RangeCredit
                    set={this.setAmount}
                    amount={this.state.amount}
                  />
                </InputGroup.Text>
              </InputGroup>
            </Col>

            <Col xl={3} md={6} sm={12}>
              <InputGroup className="mb-1 bg-form">
                <InputGroup.Text>Rows</InputGroup.Text>
                <InputGroup.Text ref={this.wrapperRef}>
                  <Button
                    onClick={(e) => {
                      this.handleChangeRows(e, 9);
                    }}
                    variant={"btn btn-s-4 btn-sm active"}
                  >
                    9
                  </Button>
                  <Button
                    onClick={(e) => {
                      this.handleChangeRows(e, 16);
                    }}
                    variant={"btn btn-s-4 btn-sm"}
                  >
                    16
                  </Button>
                  <Button
                    onClick={(e) => {
                      this.handleChangeRows(e, 25);
                    }}
                    variant={"btn btn-s-4 btn-sm"}
                  >
                    25
                  </Button>
                </InputGroup.Text>
              </InputGroup>
            </Col>

            <Col xl={3} md={6} sm={12}>
              <InputGroup className="mb-1 bg-form">
                <InputGroup.Text>Risk</InputGroup.Text>
                <InputGroup.Text ref={this.wrapperRef2}>
                  <Button
                    onClick={(e) => {
                      this.handleChangeRisk(e, "Low");
                    }}
                    variant={"btn btn-s-4 btn-sm active"}
                  >
                    Low
                  </Button>
                  <Button
                    onClick={(e) => {
                      this.handleChangeRisk(e, "Medium");
                    }}
                    variant={"btn btn-s-4 btn-sm"}
                  >
                    Medium
                  </Button>
                  <Button
                    onClick={(e) => {
                      this.handleChangeRisk(e, "High");
                    }}
                    variant={"btn btn-s-4 btn-sm"}
                  >
                    High
                  </Button>
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>

          {bet === "manual" && (
            <Row>
              <Col md={5} className={"m-auto"}>
                <Button
                  variant={"btn btn-block btn-bet btn-xl my-2"}
                  id={"bet"}
                  disabled={inputDisabled}
                  type="submit"
                >
                  {this.state.buttonText}
                </Button>
              </Col>
            </Row>
          )}
        </Form>

        {bet === "auto" && (
          <>
            <AutoBet engine={this.state.engine} amount={this.state.amount} />
          </>
        )}
        <div
          className="btn-group btn-group-toggle mb-1 ml-1"
          data-toggle="buttons"
        >
          <label
            className={
              "" + bet === "manual"
                ? "btn bg-cs2 btn-xs active"
                : "btn bg-cs2 btn-xs "
            }
          >
            <input
              disabled={inputDisabled}
              onClick={(e) => this.betMode("manual")}
              type="radio"
            />{" "}
            Manual
          </label>
          <label
            className={
              "" + bet === "auto"
                ? "btn bg-cs2 btn-xs active"
                : "btn bg-cs2 btn-xs "
            }
          >
            <input
              disabled={inputDisabled}
              onClick={(e) => this.betMode("auto")}
              type="radio"
            />{" "}
            Auto
          </label>
        </div>
      </>
    );
  }
}

Bet.propTypes = {
  coin: PropTypes.string,
  credit: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
  credit: state.items.credit,
});

export default connect(mapStateToProps, { gameCoin, setWallet })(Bet);
