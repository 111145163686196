import React, { Component } from 'react'
import {Modal, Dropdown, Row, Col} from "react-bootstrap";
import {userUID} from "../../../../Auth";
import {Event, sendNotfication, decode} from "../../../../Helper";
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import C from "../../../../Constant";
import socket from "../../../../Socket";
import storage from "../../../../Storage";


class Main extends Component{
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            value: 0,
            baseURL: "https://wall.lootably.com/?placementID=cligjs0lq03j8012t1m5nhiul&sid="
        };
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.setState({baseURL: this.state.baseURL + userUID()})
        if(Event.listenerCount("offerwall") === 0) {
            Event.on("offerwall", () => {
                this.setState({show: true});
            })
            socket.on(C.LOOTABLY_POSTBACK, data => {
                let result = decode(data);
                if(result.success) {
                  sendNotfication(result.message, 'success', 'bottom-left');
                } else {
                  sendNotfication(result.message, 'info', 'bottom-left');
                }
            })
            socket.on(C.TOROX_POSTBACK, data => {
              let result = decode(data);
              if(result.success) {
                sendNotfication(result.message, 'success', 'bottom-left');
              } else {
                sendNotfication(result.message, 'info', 'bottom-left');
              }
            })
        }
    }

    componentWillUnmount() {
        socket.off(C.LOOTABLY_POSTBACK);
        socket.off(C.TOROX_POSTBACK);
        // Event.off("offerwall");
    }

    handleClose(){
        this.setState({ show: false, value: 0 })
    }

    handleChangeIndex = (index) => {
        this.setState({value: index});
    };

    handleChange(event, newValue) {
        this.setState({value: newValue});
    }

    render() {
        const { t, disableButton } = this.props;
        return(
            <>
                {
                    !disableButton &&
                    <button onClick={ e => this.setState({ show: !this.state.show }) } className={"dropdown-item font-14"}>
                        {/* <i className="mdi mdi-currency-usd freecreditsicon text-muted mr-2 text-drop"/> */}
                        <svg className="mr-2 text-drop dollaricon" version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 512 512" fill="#7a88af" stroke="#7a88af" >
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <g> 
                                    <path style={{fill: "#7a88af!important"}} d="M406.195,383.984c-8.391,15.734-19.922,28.859-34.516,39.609c-14.656,10.719-32.188,18.703-52.563,23.969 c-8.906,2.25-18.234,3.813-27.703,5.094V512h-70.828v-58.156c-20.172-1.703-39.453-4.844-57.609-9.844 c-27.719-7.594-64.016-38.25-64.016-38.25c-3.109-1.813-5.172-5-5.609-8.531c-0.453-3.563,0.766-7.156,3.313-9.688l35.484-35.5 c3.828-3.781,9.766-4.5,14.359-1.688c0,0,26.563,23.063,46.688,28.563c20.125,5.469,40.094,8.219,60.016,8.219 c25.125,0,45.891-4.438,62.359-13.313c16.5-8.938,24.719-22.75,24.719-41.625c0-13.594-4.031-24.313-12.172-32.188 c-8.109-7.813-21.828-12.734-41.188-14.891l-63.563-5.469c-37.641-3.672-66.672-14.172-87.063-31.375 c-20.453-17.266-30.609-43.453-30.609-78.453c0-19.375,3.906-36.625,11.766-51.797c7.875-15.172,18.563-27.984,32.172-38.422 c13.594-10.469,29.438-18.313,47.469-23.531c7.547-2.188,15.453-3.625,23.484-4.938V0h70.828v50.094 c16.531,1.625,32.266,4.281,46.906,8.313c24.844,6.781,50.938,27.188,50.938,27.188c3.266,1.688,5.484,4.875,6.047,8.5 c0.563,3.688-0.641,7.313-3.219,9.969l-33.281,33.781c-3.547,3.594-9.031,4.531-13.563,2.188c0,0-19.703-14.031-36.734-18.469 c-17.016-4.438-34.891-6.688-53.719-6.688c-24.609,0-42.797,4.719-54.531,14.109c-11.781,9.453-17.625,21.734-17.625,36.875 c0,13.641,4.109,24.078,12.531,31.359c8.359,7.344,22.469,12.109,42.359,14.125l55.703,4.75 c41.297,3.656,72.563,14.625,93.734,32.922c21.203,18.328,31.781,45.016,31.781,80.016 C418.742,350.016,414.554,368.281,406.195,383.984z"></path> 
                                </g> 
                            </g>
                        </svg>
                        {t('free_credits')}
                    </button>
                }
                <Modal
                    size={'lg'}
                    backdrop={'static'}
                    centered={true}
                    scrollable={false}
                    show={this.state.show}
                    onHide={this.handleClose}
                    aria-labelledby="wallet-md-modal"
                >
                    <Modal.Header className={this.state.paddingHeader}>
                        {t('free_credits')}
                        <button type="button" className="close p-2" onClick={this.handleClose}>
                            <i className={'mdi mdi-close'}/>
                        </button>
                    </Modal.Header>
                    <Modal.Body className={ this.state.padding + ' wallet-modal free-money-btns'}>
                        <AppBar position="static" color="transparent">
                            <Tabs
                                value={this.state.value}
                                onChange={this.handleChange}
                                indicatorColor="primary"
                                variant="fullWidth"
                                aria-label="full width tabs"
                                centered
                                className="tabsfreemoney"
                            >
                                <Tab label={'OfferToro'}/>
                                <Tab label={'Lootably'}/>
                                <Tab label={'AdGateMedia'} />
                            </Tabs>
                        </AppBar>
                        <div className="pt-2 offerwalltop">
                            <SwipeableViews
                            axis={'x' === 'rtl' ? 'x-reverse' : 'x'}
                            index={this.state.value}
                            onChangeIndex={this.handleChangeIndex}
                            >
                                <div
                                    role="tabpanel"
                                    hidden={this.state.value !== 0}
                                    id={`full-width-tabpanel-0`}
                                    aria-labelledby={`full-width-tab-0`}
                                >
                                    {this.state.value === 0 && (
                                        <Box p={0}>
                                            <Typography component={'span'}>
                                                <iframe className="offerwalls" src={`https://torox.io/ifr/show/30454/${storage.getKey('uid')}/15512`} frameBorder="0" style={{width: '100%', height: '600px'}} ></iframe> 
                                            </Typography>
                                        </Box>
                                    )}
                                </div>
                                <div
                                    role="tabpanel"
                                    hidden={this.state.value !== 1}
                                    id={`full-width-tabpanel-0`}
                                    aria-labelledby={`full-width-tab-0`}
                                >
                                    {this.state.value === 1 && (
                                        <Box p={0}>
                                            <Typography component={'span'}>
                                                <iframe className="offerwalls" title="Lootably Offer Wall" src={this.state.baseURL} style={{width: '100%', height: '600px'}}/>
                                            </Typography>
                                        </Box>
                                    )}
                                </div>
                                <div
                                    role="tabpanel"
                                    hidden={this.state.value !== 2}
                                    id={`full-width-tabpanel-0`}
                                    aria-labelledby={`full-width-tab-0`}
                                >
                                    {this.state.value === 2 && (
                                        <Box p={0}>
                                            <Typography component={'span'}>
                                                <iframe className="offerwalls" src={`https://wall.adgaterewards.com/nqaTp20/${storage.getKey('uid')}`} frameBorder="0" style={{ width: '100%', height: '600px' }} ></iframe>
                                            </Typography>
                                        </Box>
                                    )}
                                </div>
                            </SwipeableViews>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default Main;
