import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Button, InputGroup, Form } from "react-bootstrap";
import RangeCredit from "../../Components/Game/Addons/Range";
import { gameCoin } from "../../../actions/gameCoin";
import { setRouletteNumber } from "../../../actions/gameRoulette";
import { setWallet } from "../../../actions/gameWallet";
import storage from "../../../Storage";

import {
  Event,
  __,
  isLogged,
  getDefaultBetAmount,
  isValidNumber,
  wait,
  forceSatoshiFormat,
  sendNotfication,
  defaultBetAmount,
} from "../../../Helper";

class Bet extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      bet: "manual",
      started: false,
      amount: defaultBetAmount(this.props.coin),
      inputDisabled: false,
      buttonText: "Spin",
      betNumber: 10,
      firstBetNumber: 10,
      onWin: 0,
      onLost: 0,
      stopOnWin: 0,
      total_bet: forceSatoshiFormat(0.0),
      chips: [1, 2, 3, 4, 5, 6, 7],
    };
    this.handleBet = this.handleBet.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.outRef = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    let { engine } = this.state;
    this.props.gameCoin();
    engine.on("error", (data) => this.error(data));
    engine.on("busted_roulette", () => this.busted());
    engine.on("roulette_add_amount", (data) => this.addAmount(data));
    engine.on("roulette_reduce_amount", (data) => this.undoAmount(data));
    engine.on("roulette_reset_amount", () => this.resetAmount());
    this.props.setRouletteNumber(this.state.amount);
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    let { engine } = this.state;
    engine.init = false;
    this._isMounted = false;
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.outRef && !this.outRef.current.contains(event.target)) {
      let value = parseFloat(this.state.amount);

      if (!isValidNumber(value)) {
        return this.setState({ amount: 0.1 });
      }
      value = __.clamp(getDefaultBetAmount(this.props.coin), 0.001, value);
      value = forceSatoshiFormat(value);
      if (!__.isNaN(value)) this.setState({ amount: value });
    }
  }

  handleInputChange(event) {
    let target = event.target;
    let value = target.value;
    this.setState({ [target.name]: value });
    this.props.setRouletteNumber(this.state.amount);
  }

  busted() {
    this.setState({ inputDisabled: false, total_bet: forceSatoshiFormat(0.0) });
  }

  addAmount(value) {
    let add = parseFloat(this.state.total_bet) + parseFloat(value);
    this.setState({ total_bet: forceSatoshiFormat(add) });
  }

  undoAmount(value) {
    if (this.state.total_bet <= 0) return;

    let reduce = parseFloat(this.state.total_bet) - parseFloat(value);
    this.setState({ total_bet: forceSatoshiFormat(reduce) });
  }

  resetAmount() {
    this.setState({ total_bet: forceSatoshiFormat(0.0) });
  }

  placeBet() {
    if (this._isMounted) {
      let { engine } = this.state;

      wait(100).then(() => {
        engine.init = true;
        engine.coin = this.props.coin;
        engine.start();
      });
    }
  }

  error(data) {
    if (this._isMounted) {
      this.setState({ inputDisabled: false });

      sendNotfication(data.message, "info", "top-center");

      Event.emit("reset_table");

      if (data.code === "credit") {
        this.props.setWallet(true, data.uid);
      }
    }
  }

  handleBet(e) {
    e.preventDefault();
    let { amount, bet, started } = this.state;

    // Check logged user
    if (!isLogged) {
      return Event.emit("showAuthModal", true);
    }

    if (parseFloat(amount) <= 0) {
      this.setState({ amount: forceSatoshiFormat(0.001) });
    }

    this.setState({ inputDisabled: true });
    this.placeBet();
  }

  setAmount = (value) => {
    if (!this.state.inputDisabled) {
      if (!__.isNaN(value)) {
        this.setState({ amount: value });
        storage.setKey("lam", value);
        this.props.setRouletteNumber(value);
      }
    }
  };

  render() {
    let { inputDisabled } = this.state;

    return (
      <>
        <form
          className="w-100 mt-1"
          onSubmit={(e) => {
            this.handleBet(e);
          }}
        >
          <Row>
            <Col sm={12} className={"d-md-none d-sm-block"}>
              <Button
                variant={"btn btn-xs btn-block btn-bet py-2 mb-2 "}
                disabled={inputDisabled}
                type="submit"
              >
                {this.state.buttonText}
              </Button>
            </Col>
            <Col xl={6} md={7}>
              <InputGroup className="mb-1">
                <InputGroup.Text>
                  <img
                    src={
                      "/assets/images/" + __.toLower(this.props.coin) + ".png"
                    }
                    className={"mini-coin-7"}
                    alt=""
                  />
                  Chip Amount
                </InputGroup.Text>
                <Form.Control
                  ref={this.outRef}
                  disabled={inputDisabled}
                  className="form-control"
                  type="text"
                  id="amount"
                  name="amount"
                  value={this.state.amount}
                  autoComplete={"off"}
                  onKeyUp={this.handleInputChange}
                  onChange={this.handleInputChange}
                ></Form.Control>
                <InputGroup.Text>
                  <RangeCredit
                    set={this.setAmount}
                    amount={this.state.amount}
                  />
                </InputGroup.Text>
              </InputGroup>
            </Col>

            <Col xl={6} md={5}>
              <InputGroup className="mb-1">
                <InputGroup.Text>
                  <img
                    src={
                      "/assets/images/" + __.toLower(this.props.coin) + ".png"
                    }
                    className={"mini-coin-7"}
                    alt=""
                  />
                  TOTAL BET
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  disabled={true}
                  className="form-control"
                  value={this.state.total_bet}
                ></Form.Control>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col md={5} sm={12} className="m-auto hidden-sm">
              <div className={"form-group rev mb-1"}>
                <Button
                  variant={"btn btn-md btn-block mt-2 btn-bet"}
                  disabled={inputDisabled}
                  type="submit"
                >
                  {this.state.buttonText}
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </>
    );
  }
}

Bet.propTypes = {
  coin: PropTypes.string.isRequired,
  credit: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
  credit: state.items.credit,
});

export default connect(mapStateToProps, {
  gameCoin,
  setRouletteNumber,
  setWallet,
})(Bet);
