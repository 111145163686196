import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import C from "../../Constant";
import {__, encode, decode, wait, Event, getGameID, sendNotfication} from "../../Helper";
import socket from "../../Socket";
import storage from "../../Storage";
import {gameCoin} from "../../actions/gameCoin";
import axios from "axios";

class Frame extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isLogged: storage.getKey('logged'),
            started: false,
            message: null,
            height: 505,
            token: storage.getKey('token'),
            url: null,
            isRealLoading: false,
            bt: null,
            sessionId: null,
        }
    }

    componentDidMount(){
        this._isMounted = true;
        
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        
        this.props.gameCoin();

        Event.on('coin_changed', () => this.restartGame());
        Event.on('game_changed', () => this.checkAndStart());
    }

    componentWillUnmount(){
        this._isMounted = false;
        if (this.state.bt) this.state.bt.kill();
    }

    checkAndStart(){
        this.setState({ started: false });
    }

    restartGame = () => {
        if(this._isMounted)
        {
            if(this.state.started)
            {
                this.setState({ started: false, url: null });
                wait(400).then(() => {
                    this.startReal();
                })
            }
        }
    }
    onTokenExpired = () => {
        /*!-template-on-token-expired--*/
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://testinglobby.slotegrator.com/sportsbook/refresh-token/'+this.state.sessionId,
                method: 'GET'
            })
            .then(function (launchData) {
                console.log(launchData);
                resolve(launchData.token)
            })
            .catch( error => {
                reject(error)
            });
        })
        /*--template-on-token-expired-!*/
    }
    startReal = () => {
            
        if(this.state.token !== null && this.state.isLogged)
        {
            this.setState({ isRealLoading: true });
            this.setState({ message: null })
            // Event.emit('hide_chat');
            Event.emit('toggle_chat', false);
            socket.emit(C.GET_REAL_SPORT, encode({ game: this.props.game.uuid, coin: this.props.coin }))
            socket.on(C.GET_REAL_SPORT, (data) => {
                socket.off(C.GET_REAL_SPORT);
                this.setState({ isRealLoading: false });
                let res = decode(data);
                if (res.success) {
                    this.setState({ started: true, url: res.data.url, token: res.data.token, sessionId: res.data.session_id });
                    this.state.bt = new BTRenderer().initialize({
                        brand_id: "2304251608915120128",
                        // brand_id: "2372808000315465728",
                        token: res.data.token,
                        themeName: "demo-cardinal-dark-table",
                        lang: "en",
                        target: document.getElementById('bettech'),
                        minFrameHeight: 700,
                        betSlipOffsetTop: 0,
                        betslipZIndex: 999,
                        onTokenExpired: this.onTokenExpired,
                        onRouteChange: function() {
                            /*!-template-on-route-change--*/
                            console.log("on route change", arguments);
                            /*--template-on-route-change-!*/
                        },
                        onLogin: function() {
                            /*!-template-on-login--*/
                            console.log("The login URL does not specified in contract configuration, nothing to do...");
                            /*--template-on-login-!*/
                        },
                        onRegister: function() {
                            /*!-template-on-register--*/
                            console.log("The register URL does not specified in contract configuration, nothing to do...");
                            /*--template-on-register-!*/
                        },
                        onSessionRefresh: function() {
                            /*!-template-on-session-refresh--*/
                            console.log("The login URL does not specified in contract configuration, nothing to do...");
                            /*--template-on-session-refresh-!*/
                        },
                        onRecharge: function() {
                            /*!-template-on-recharge--*/
                            console.log("The deposit URL does not specified in contract configuration, nothing to do...");
                            /*--template-on-recharge-!*/
                        }
                    });
                } else {
                    sendNotfication('There is some issues on game provider.', 'success','bottom-left');
                }
            });
        } else {
            Event.emit('login_form');
        }
    }

    render() {

        const background = '/assets/images/sportsbet.jpg';

        return (
            <>
                {this.state.started ?
                    // <iframe className={'fullScreen'} src={this.state.url}></iframe>
                    <div id="bettech"></div>
                    :
                    <div style={{ background: `url(${background}) center center / 100% no-repeat` }} className="sc-jEUCeg bSWQnZ">
                        <div className="mask">
                            <div className="tips">
                                <p className="ftw">
                                    Entering a third-party game. 
                                    The balance of the selected currency will be displayed in USD, and if you change currency<br/> while playing, the game will refresh and restart.
                                    <br/>
                                    {this.state.message}
                                </p>
                                <div className="btn-wrap">
                                    <button className="sc-kEjbxe sc-crrsfI cLOWRl jcNNmq btn btn-normal realplay" onClick={this.startReal}>
                                        <div className="button-inner">
                                          {this.state.isRealLoading ? 
                                            <>
                                                <div className="spinner-border spinner-slots-real spinner-border-sm text-white mr-1" role="status" />
                                            </>
                                            :
                                            <>
                                                <i className="mdi mdi-play-circle-outline mr-1 align-middle" />
                                           </>
                                          }
                                          Real Play
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        );
    }
}

Frame.propTypes = {
    coin: PropTypes.string.isRequired,
    credit: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin,
    credit: state.items.credit,
});

export default connect(mapStateToProps, { gameCoin })(Frame);
