import React from "react";
import { Button, Col, InputGroup, Form, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import RangeCredit from "../../Components/Game/Addons/Range";
import AutoBet from "../../Components/Game/Addons/AutoBet";
import { gameCoin } from "../../../actions/gameCoin";
import { setWallet } from "../../../actions/gameWallet";
import storage from "../../../Storage";
import {
  __,
  wait,
  isValidNumber,
  forceSatoshiFormat,
  sendNotfication,
  Event,
  playAudio,
  isLogged,
  funPlay,
  defaultBetAmount,
  getDefaultBetAmount,
} from "../../../Helper";

class Bet extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      started: false,
      bet: "manual",
      playing: false,
      buttonText: "Bet",
      riskName: "Medium",
      amount: defaultBetAmount(this.props.coin),
      game_status: null,
      lock: false,
      inputDisabled: false,
      formData: {
        segment: 8,
        risk: "Medium",
      },
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBet = this.handleBet.bind(this);
    this.wrapperRef = React.createRef();
    this.wrapperRef2 = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.outRef = React.createRef();
  }

  componentDidMount() {
    let { engine } = this.state;
    this._isMounted = true;

    this.props.gameCoin();
    engine.on("play", (data) => this.play(data));
    engine.on("busted", (data) => this.busted(data));
    engine.on("error", (data) => this.error(data));
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    let { engine, started } = this.state;
    this._isMounted = false;
    engine.init = false;
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.outRef && !this.outRef.current.contains(event.target)) {
      let value = parseFloat(this.state.amount);

      if (!isValidNumber(value)) {
        return this.setState({ amount: 0.1 });
      }
      value = __.clamp(getDefaultBetAmount(this.props.coin), 0.001, value);
      value = forceSatoshiFormat(value);
      if (!__.isNaN(value)) this.setState({ amount: value });

      Event.emit("set_amount", value);
    }
  }

  error(data) {
    if (this._isMounted) {
      sendNotfication(data.message, "info", "top-center");

      if (data.code === "credit") this.props.setWallet(true, data.uid);

      this.setState({ inputDisabled: false });

      if (this.state.started) {
        this.setState({ started: false, buttonText: "Start Auto Mode" });
      }
    }
  }

  play(data) {
    if (this._isMounted) {
      this.setState({
        hash: data.hash,
        game_status: "started",
        lock: true,
        playing: true,
        inputDisabled: true,
      });
    }
  }

  busted(data) {
    if (this._isMounted) {
      let { profit } = data;
      let { engine } = this.state;

      let isWin = false;

      if (parseFloat(profit) !== 0) {
        isWin = true;
      }

      wait(100).then(() => {
        this.setState({
          lock: false,
          playing: false,
          inputDisabled: false,
          playing: false,
        });
        engine.init = false;
      });

      if (isWin === true) playAudio("win.mp3");
    }
  }

  placeBet() {
    if (this._isMounted) {
      let { engine, amount } = this.state;
      let { segment, risk } = this.state.formData;

      engine.init = true;
      engine.coin = this.props.coin;
      engine.amount = amount;
      engine.segment = segment;
      engine.risk = risk;
      engine.start();
    }
  }

  isCorrectBet(amount) {
    if (__.trim(amount) === "") return false;

    if (!isValidNumber(amount)) return false;

    if (parseFloat(amount) <= 0) {
      this.setState({ amount: forceSatoshiFormat(0.001) });
    }

    return true;
  }

  handleBet(e) {
    e.preventDefault();

    // Check logged user
    if (!isLogged) {
      return Event.emit("showAuthModal", true);
    }

    // Check is Correct Bet
    if (!this.isCorrectBet(this.state.amount)) return false;

    this.setState({ inputDisabled: true });

    wait(100).then(() => {
      if (this.state.playing !== true) {
        // Check for Double request
        if (this.state.lock === true) return false;

        this.placeBet();
      }
    });
  }

  handleInputChange(event) {
    var target = event.target;
    var value = target.value;
    var name = target.name;
    this.setState({ [name]: value });
    Event.emit("set_amount", value);
  }

  handleRisk = (e, value) => {
    if (this._isMounted) {
      e.preventDefault();
      for (var i = 0; i < this.wrapperRef2.current.children.length; i++) {
        this.wrapperRef2.current.children[i].classList.remove("active");
      }
      e.target.classList.add("active");
      if (this.state.inputDisabled) return;

      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          risk: value,
        },
      }));

      this.setState({ riskName: value });

      wait(10).then(() => {
        this.state.engine.emit("wheel_risk", {
          segment: this.state.formData.segment,
          risk: value,
        });
      });
    }
  };

  handleSegment = (e, value) => {
    if (this._isMounted) {
      e.preventDefault();
      for (var i = 0; i < this.wrapperRef.current.children.length; i++) {
        this.wrapperRef.current.children[i].classList.remove("active");
      }
      e.target.classList.add("active");
      if (this.state.inputDisabled) return;

      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          segment: value,
        },
      }));

      wait(10).then(() => {
        this.state.engine.emit("wheel_segment", {
          segment: value,
          risk: this.state.formData.risk,
        });
      });
    }
  };

  betMode = (type) => {
    this.setState({ bet: type });
  };

  setAmount = (value) => {
    if (!this.state.inputDisabled) {
      if (!__.isNaN(value)) {
        this.setState({ amount: value });
        storage.setKey("lam", value);
        Event.emit("set_amount", value);
      }
    }
  };

  render() {
    let { inputDisabled, bet, started, riskName } = this.state;

    return (
      <>
        <form
          className="w-100 mt-1"
          onSubmit={(e) => {
            this.handleBet(e);
          }}
        >
          <Row>
            <Col xl={6} md={6} sm={12}>
              <InputGroup className="mb-1">
                <InputGroup.Text>
                  <img
                    src={
                      "/assets/images/" + __.toLower(this.props.coin) + ".png"
                    }
                    className={"mini-coin-7"}
                    alt=""
                  />
                  BET
                </InputGroup.Text>
                <Form.Control
                  ref={this.outRef}
                  disabled={inputDisabled}
                  className="form-control"
                  id="amount"
                  name="amount"
                  type="text"
                  value={this.state.amount}
                  autoComplete={"off"}
                  onKeyUp={this.handleInputChange}
                  onChange={this.handleInputChange}
                ></Form.Control>
                <InputGroup.Text>
                  <RangeCredit
                    set={this.setAmount}
                    amount={this.state.amount}
                  />
                </InputGroup.Text>
              </InputGroup>
            </Col>

            <Col xl={3} md={3} sm={12}>
              <InputGroup className="mb-1 bg-form">
                <InputGroup.Text>SEGMENT</InputGroup.Text>
                <InputGroup.Text ref={this.wrapperRef} className="buttonsegment">
                  <Button
                    onClick={(e) => {
                      this.handleSegment(e, 8);
                    }}
                    variant={"btn btn-s-4 btn-sm active"}
                  >
                    8
                  </Button>
                  <Button
                    onClick={(e) => {
                      this.handleSegment(e, 16);
                    }}
                    variant={"btn btn-s-4 btn-sm"}
                  >
                    16
                  </Button>
                  <Button
                    onClick={(e) => {
                      this.handleSegment(e, 25);
                    }}
                    variant={"btn btn-s-4 btn-sm"}
                  >
                    25
                  </Button>
                  <Button
                    onClick={(e) => {
                      this.handleSegment(e, 35);
                    }}
                    variant={"btn btn-s-4 btn-sm"}
                  >
                    35
                  </Button>
                </InputGroup.Text>
              </InputGroup>
            </Col>

            <Col xl={3} md={3} sm={12}>
              <InputGroup className="mb-1 bg-form">
                <InputGroup.Text>RISK</InputGroup.Text>
                <InputGroup.Text ref={this.wrapperRef2}>
                  <Button
                    onClick={(e) => {
                      this.handleRisk(e, "medium");
                    }}
                    variant={"btn btn-s-4 btn-sm active"}
                  >
                    Medium
                  </Button>
                  <Button
                    onClick={(e) => {
                      this.handleRisk(e, "high");
                    }}
                    variant={"btn btn-s-4 btn-sm"}
                  >
                    High
                  </Button>
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col xl={5} md={5} sm={12} className={"m-auto"}>
              {bet === "manual" && (
                <div className={"form-group rev mt-2 mb-0"}>
                  <Button
                    variant={"btn btn-md btn-block mt-2 btn-bet"}
                    disabled={inputDisabled}
                    type="submit"
                  >
                    {this.state.buttonText}
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </form>
        {bet === "auto" && (
          <AutoBet
            engine={this.state.engine}
            formData={this.state.formData}
            amount={this.state.amount}
          />
        )}
        <div
          className="btn-group btn-group-toggle my-1 ml-1"
          data-toggle="buttons"
        >
          <label
            className={
              "" + bet === "manual"
                ? "btn bg-cs2 btn-xs active"
                : "btn bg-cs2 btn-xs "
            }
          >
            <input
              disabled={inputDisabled}
              onClick={(e) => this.betMode("manual")}
              type="radio"
            />{" "}
            Manual
          </label>
          <label
            className={
              "" + bet === "auto"
                ? "btn bg-cs2 btn-xs active"
                : "btn bg-cs2 btn-xs "
            }
          >
            <input
              disabled={inputDisabled}
              onClick={(e) => this.betMode("auto")}
              type="radio"
            />{" "}
            Auto
          </label>
        </div>
      </>
    );
  }
}

Bet.propTypes = {
  coin: PropTypes.string,
  credit: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
  credit: state.items.credit,
});

export default connect(mapStateToProps, { gameCoin, setWallet })(Bet);
