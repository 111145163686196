import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  Row,
  Col,
  InputGroup,
  Form,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { gameCoin } from "../../../../actions/gameCoin";
import { setWallet } from "../../../../actions/gameWallet";
import storage from "../../../../Storage";
import C from "../../../../Constant";
import RangeCredit from "../../../Components/Game/Addons/Range";
import { userUID } from "../../../../Auth";
import {
  Event,
  __,
  wait,
  isValidNumber,
  forceSatoshiFormat,
  sendNotfication,
  secureRandomNumber,
  getDefaultBetAmount,
  isMobile,
  defaultBetAmount,
  getRand,
} from "../../../../Helper";

class ManualBet extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      buttonText: "BET",
      buttonType: "",
      playing: false,
      inputDisabled: false,
      risk: "low",
      numbers: [],
      multi: [],
      payout: [],
      bitError: false,
      outError: false,
      lock: false,
      holding: false,
      amount: defaultBetAmount(this.props.coin),
      isLogged: storage.getKey("logged") !== null ? true : false,
      coin: storage.getKey("coin"),
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleRisk = this.handleRisk.bind(this);
    this.handleBet = this.handleBet.bind(this);
    this.clearTable = this.clearTable.bind(this);
    this.autoPick = this.autoPick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.outRef = React.createRef();
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    let { engine } = this.state;
    this._isMounted = true;

    //Set Coin for Redux
    this.props.gameCoin();

    engine.on(C.STATUS_KENO, (data) => this.checkStatus(data));
    engine.on(C.WAITING_KENO, () => this.checkWaitingGame());
    engine.on(C.BUSTED_KENO, () => this.checkBustedGame());
    engine.on(C.STARTED_KENO, () => this.checkStartedGame());
    engine.on(C.PLAY_KENO, (data) => this.handlePlay(data));
    engine.on(C.KENO_AMOUNT, (data) => this.amount(data));
    engine.on(C.ERORR_KENO, (data) => this.handleError(data));
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.outRef && !this.outRef.current.contains(event.target)) {
      let value = parseFloat(this.state.amount);

      if (!isValidNumber(value)) {
        return this.setState({ amount: 0.1 });
      }
      value = __.clamp(getDefaultBetAmount(this.props.coin), 0.001, value);
      value = forceSatoshiFormat(value);
      this.setState({ amount: value });
    }
  }

  handleError(data) {
    if (this._isMounted) {
      sendNotfication(data.message, "info", "top-center");

      this.setState({ holding: false, look: false, playing: false });

      if (data.code === "credit") this.props.setWallet(true, data.uid);

      this.setDefaultButton();
    }
  }

  handleRisk = (e, value) => {
    e.preventDefault();
    for (var i = 0; i < this.wrapperRef.current.children.length; i++) {
      this.wrapperRef.current.children[i].classList.remove("active");
    }
    e.target.classList.add("active");
    if (!this.state.started) {
      this.setState({ risk: value });
    }
  };

  handleInputChange(event) {
    let target = event.target;
    let value = target.value;
    let name = target.name;

    if (__.isEmpty(value)) {
      if (name === "amount") this.setState({ bitError: "red-border" });

      if (name === "risk") this.setState({ outError: "red-border" });
    } else {
      if (name === "amount") this.setState({ bitError: false });

      if (name === "risk") this.setState({ outError: false });
    }

    this.setState({ [name]: value });
  }

  setDefaultButton() {
    if (this._isMounted) {
      this.setState({
        inputDisabled: false,
        buttonType: "",
        buttonText: "BET",
      });
    }
  }

  setDisabledButton() {
    if (this._isMounted) {
      this.setState({
        inputDisabled: true,
        buttonType: "",
        buttonText: "BET",
      });
    }
  }

  setOutButton() {
    if (this._isMounted) {
      this.setState({
        inputDisabled: true,
        buttonType: "btn-success",
        buttonText: "CANCEL",
      });
    }
  }

  handlePlay = (data) => {
    if (this._isMounted) {
      if (parseFloat(data.uid) === parseFloat(userUID()))
        this.setState({ playing: true });
    }
  };

  checkWaitingGame() {
    if (this._isMounted) {
      this.setState({ inputDisabled: false });

      if (this.state.holding === true) {
        this.setState({ holding: false });
        this.placeBet();
      } else this.clearTable();

      for (var i = 1; i < 41; i++) {
        if (document.getElementById("b_" + i) !== null)
          document.getElementById("b_" + i).classList.remove("alerts");
        document.getElementById("b_" + i).removeAttribute("disabled");
      }
    }
  }

  checkStartedGame() {
    if (this._isMounted) {
      this.setState({ inputDisabled: true });

      if (!this.state.playing) {
        this.clearTable(false);
      }

      if (this.state.lock === true || this.state.playing === true) {
        this.setState({ lock: false });
        this.setDisabledButton();
      }
    }
  }

  checkBustedGame = () => {
    if (this._isMounted) {
      let { engine } = this.state;

      this.setState({ inputDisabled: true });

      if (this.state.playing) {
        this.clearTable();
      }

      if (this.state.holding === false) {
        engine.init = false;
        this.setState({ playing: false });
        this.setDefaultButton();
      }
    }

    for (var i = 1; i < 41; i++) {
      document.getElementById("b_" + i).removeAttribute("disabled");
    }
  };

  checkStatus(data) {
    if (this._isMounted) {
      switch (data.status) {
        case "waiting_keno":
          this.checkWaitingGame();
          break;
        case "started_keno":
          this.checkStartedGame();
          break;
        case "busted_keno":
          this.checkBustedGame();
          break;
        default:
      }
    }
  }

  isCorrectBet(amount) {
    if (amount === 0) return false;

    if (!isValidNumber(amount)) return false;

    return true;
  }

  placeBet() {
    if (this._isMounted) {
      let { engine } = this.state;

      // Disable For double queue or network delay
      if (this.state.lock === true) return;

      this.setState({
        lock: true,
        playing: true,
        inputDisabled: true,
        buttonType: "btn-secondary",
        buttonText: "Please Wait...",
      });

      wait(10).then(() => {
        engine.init = true;
        engine.coin = this.props.coin;
        engine.numbers = this.state.numbers;
        engine.amount =
          forceSatoshiFormat(this.state.amount) * this.state.numbers.length;
        engine.force();
      });
    }
  }

  holdBet() {
    if (this._isMounted) {
      if (this.state.holding === true) {
        this.setDefaultButton();
        this.setState({ holding: false });
      } else {
        this.setOutButton();
        this.setState({ holding: true });
      }
    }
  }

  handleBet(e) {
    if (this._isMounted) {
      e.preventDefault();

      let { amount } = this.state;

      // Check User
      if (!this.state.isLogged) {
        return Event.emit("showAuthModal", true);
      }

      if (this.state.numbers.length < 3) {
        return sendNotfication(
          "Please select at least 3 number",
          "info",
          "top-center"
        );
      }

      // Check is Correct Bet
      if (!this.isCorrectBet(this.state.amount)) return false;

      // Check for Double request
      if (this.state.lock === true) return false;

      if (parseFloat(amount) <= 0) {
        this.setState({ amount: forceSatoshiFormat(0.001) });
      } else {
        amount = Math.min(
          parseFloat(storage.getKey("credit")),
          parseFloat(amount)
        );
        this.setState({ amount: forceSatoshiFormat(amount) });
      }

      // Check Game Status to Play
      switch (this.state.engine.gameStatus) {
        case "waiting_keno":
          this.placeBet();
          break;

        case "started_keno":
          this.holdBet();
          break;

        case "busted_keno":
          this.holdBet();
          break;

        default:
      }
    }
  }

  selectNum(num, auto = false) {
    if (this._isMounted) {
      if (this.state.engine.gameStatus === "waiting_keno") {
        if (!this.state.numbers.includes(num)) {
          if (this.state.numbers.length <= 10) {
            this.setState((state) => ({ numbers: [num, ...state.numbers] }));
            this.addMulti(num, auto);
            this.addPayout(num, auto);
          }
        } else {
          const removeN = (num) => {
            return __.without(this.state.numbers, num);
          };
          this.setState(() => ({ numbers: removeN(num) }));
          this.removeMulti(num);
          this.removePayout(num);
        }
      }
    }
  }

  clearTable(mode = true) {
    if (this._isMounted) {
      if (this.state.engine.gameStatus === "waiting_keno" || !mode) {
        this.setState({
          numbers: [],
          uniqueMulti: [],
          multi: [],
          payout: [],
        });

        for (var i = 0; i < 41; i++) {
          if (document.getElementById("b_" + i) !== null) {
            document.getElementById("b_" + i).classList.remove("bg-success");
            document.getElementById("b_" + i).classList.remove("text-white");
            document.getElementById("b_" + i).classList.remove("bg-danger");
          }
        }
      }
    }
  }

  addMulti(num, isAuto) {
    const { risk } = this.state;
    var multis;

    if (risk === "low") {
      multis = [0, 0, 0, 1.4, 2.25, 4.5, 8.0, 17.0, 50.0, 80.0, 100.0];
    } else if (risk === "medium") {
      multis = [0, 0, 0, 1.6, 2.0, 4.0, 7.0, 26.0, 100.0, 500.0, 1000.0];
    } else if (risk === "high") {
      multis = [0, 0, 0, 0, 3.5, 8.0, 13.0, 63.0, 500.0, 800.0, 1000.0];
    }

    var n = this.state.numbers.length;
    if (isAuto) n = n - 1;

    this.state.multi.push(this.makeMulti(multis[n].toFixed(2) + "x"));
  }

  addPayout(num, isAuto) {
    var n = this.state.numbers.length;
    if (isAuto) n = n - 1;
    this.state.payout.push(this.makePayout(n));
  }

  removeMulti(num) {
    this.state.multi = __.take(this.state.multi, this.state.multi.length - 1);
  }

  removePayout(num) {
    this.state.payout = __.take(
      this.state.payout,
      this.state.payout.length - 1
    );
  }

  autoPick() {
    if (this._isMounted) {
      this.clearTable();

      var all = [];

      for (var i = 0; i < 40; i++) {
        all.push(i);
      }

      for (var i = 0; i < 10; i++) {
        wait(i * 135).then(() => {
          let rand = all[getRand(all.length)];

          __.remove(all, function (n) {
            return n === rand;
          });

          this.selectNum(rand, true);
        });
      }
    }
  }

  makePayout = (num) => {
    Event.on("coin_changed", (name) => {
      return this.clearTable();
    });

    var multis = [0, 0, 0, 1.4, 2.25, 4.5, 8.0, 17.0, 50, 80, 100];
    let choose = multis[num];
    var calc = forceSatoshiFormat(choose);
    const popover = (
      <Popover id="popover-basic">
        <Popover.Body className="p-0 bg-cs2">
          <div className="form-group mb-1">
            <div className="input-group">
              <div className="input-group-append">
                <span className="input-group-text">
                  <img
                    src={
                      "/assets/images/" + __.toLower(this.props.coin) + ".png"
                    }
                    className={"mini-coin-7"}
                    alt=""
                  />
                  Win Amount
                </span>
              </div>
              <input
                disabled={true}
                type="number"
                className="form-control text-left"
                value={calc}
              />
            </div>
          </div>
          <div className="form-group mb-0">
            <div className="input-group">
              <div className="input-group-append">
                <span className="input-group-text">Payout</span>
              </div>
              <input
                disabled={true}
                type="number"
                className="form-control text-left"
                value={num}
              />
            </div>
          </div>
        </Popover.Body>
      </Popover>
    );

    return (
      <>
        <OverlayTrigger trigger="hover" placement="bottom" overlay={popover}>
          <div className="keno-x">
            <div className="text-center bg-cs5 text-white p-1 btn-sm">
              {num}x
            </div>
            <div className="keno-payout text-center bg-cs5 text-white p-1 btn-sm">
              {num} Hits
            </div>
          </div>
        </OverlayTrigger>
      </>
    );
  };

  makeMulti = (num) => {
    var btn = "btn-sm";
    if (isMobile()) btn = "btn-xs";
    let button = React.createElement(
      "div",
      {
        id: "m_" + num,
        className: `text-center bg-cs2 text-white p-1 ${btn} `,
      },
      num
    );
    return React.createElement("div", { className: "keno-multi" }, button);
  };

  amount(data) {
    if (this._isMounted) {
      for (var i = 1; i < 41; i++) {
        document.getElementById("b_" + i).setAttribute("disabled", true);
      }

      data.amount.forEach((c, i) => {
        var amount = parseFloat(c);

        wait(i * 80).then(() => {
          if (document.getElementById("b_" + amount) !== null)
            document.getElementById("b_" + amount).classList.remove("active");
        });

        if (this.state.numbers.includes(amount)) {
          wait(i * 80).then(() => {
            if (document.getElementById("b_" + amount) !== null) {
              document.getElementById("b_" + amount).classList.add("bg-danger");
              document
                .getElementById("b_" + amount)
                .classList.add("text-white");
            }
          });
        } else {
          if (document.getElementById("b_" + amount) !== null) {
            wait(amount * 80).then(() => {
              if (document.getElementById("b_" + amount) !== null)
                document.getElementById("b_" + amount).classList.add("alerts");
            });
          }
        }
      });
    }
  }

  setAmount = (value) => {
    if (!__.isNaN(value)) {
      this.setState({ amount: value });
      storage.setKey("lam", value);
    }
  };

  render() {
    const makeNumber = (num) => {
      let disabled = false;

      if (this.state.lock === true || this.state.playing === true) {
        disabled = true;
      }

      let active = false;

      if (this.state.numbers.includes(num)) {
        active = "active";
      }

      let button = React.createElement(
        "button",
        {
          type: "button",
          id: "b_" + num,
          disabled: disabled,
          className: "btn btn-soft-secondary btn-xl btn-block " + active,
          onClick: () => this.selectNum(num),
        },
        num
      );

      return React.createElement(
        "div",
        { className: "keno-numbers", key: num },
        button
      );
    };

    const generateNumber = () => {
      let make = [];
      for (var i = 1; i < 41; i++) {
        make[i] = makeNumber(i);
      }
      return make;
    };

    let {
      inputDisabled,
      risk,
      numbers,
      buttonType,
      bitError,
      buttonText,
      amount,
      multi,
      payout,
    } = this.state;

    multi.length = Math.min(multi.length, 10);
    payout.length = Math.min(payout.length, 10);
    numbers.length = Math.min(numbers.length, 10);

    return (
      <>
        <form
          className="w-100"
          onSubmit={(e) => {
            this.handleBet(e);
          }}
        >
          <div className="grid-buttons">{generateNumber()}</div>
          <div className="keno-bottom">
            <div className="mb-0 mt-3 text-center font-14 text-white">
              {this.state.numbers.length === 0 ? (
                <>Select 1 - 10 numbers to play</>
              ) : (
                <>[ {this.state.numbers.length} ]</>
              )}
            </div>
            <div className="keno-addition h-">{multi}</div>
            <div className="keno-addition h-">{payout}</div>
          </div>
          <Row className="mt-2">
            <Col md={6} className={"col-6 px-2"}>
              <Button
                variant={"btn btn-outline-light btn-block btn-xs"}
                disabled={inputDisabled}
                onClick={this.autoPick}
              >
                Auto Pick
              </Button>
            </Col>
            <Col md={6} className={"col-6 px-2"}>
              <Button
                variant={"btn btn-outline-light btn-block btn-xs"}
                disabled={inputDisabled}
                onClick={this.clearTable}
              >
                Clear Table
              </Button>
            </Col>
          </Row>

          <Row>
            <Col sm={4} lg={4} className={"m-auto"}>
              <div className={"form-group rev mb-1"}>
                <Button
                  variant={
                    "btn btn-sm btn-block mt-2 keno-bet btn-bet " + buttonType
                  }
                  disabled={inputDisabled}
                  type="submit"
                >
                  {buttonText}
                </Button>
              </div>
            </Col>

            <Col lg={4} sm={12} className={"mt-3"}>
              <InputGroup className="mb-1">
                <InputGroup.Text>
                  <img
                    src={
                      "/assets/images/" + __.toLower(this.props.coin) + ".png"
                    }
                    className={"mini-coin-7"}
                    alt=""
                  />
                  BET
                </InputGroup.Text>
                <Form.Control
                  ref={this.outRef}
                  disabled={inputDisabled}
                  type="text"
                  className="form-control text-left"
                  id="amount"
                  name="amount"
                  max="900000000"
                  placeholder=".."
                  value={amount}
                  autoComplete={"off"}
                  required={true}
                  onKeyUp={this.handleInputChange}
                  onChange={this.handleInputChange}
                ></Form.Control>
                <InputGroup.Text>
                  <RangeCredit
                    set={this.setAmount}
                    amount={this.state.amount}
                  />
                </InputGroup.Text>
              </InputGroup>
            </Col>

            <Col lg={4} sm={12} className={"mt-3"}>
              <InputGroup className="mb-1 bg-form">
                <InputGroup.Text>Risk</InputGroup.Text>
                <InputGroup.Text ref={this.wrapperRef}>
                  <Button
                    onClick={(e) => {
                      this.handleRisk(e, "low");
                    }}
                    variant={"btn btn-s-4 btn-sm active"}
                  >
                    Low
                  </Button>
                  <Button
                    onClick={(e) => {
                      this.handleRisk(e, "medium");
                    }}
                    variant={"btn btn-s-4 btn-sm"}
                  >
                    Medium
                  </Button>
                  <Button
                    onClick={(e) => {
                      this.handleRisk(e, "high");
                    }}
                    variant={"btn btn-s-4 btn-sm"}
                  >
                    High
                  </Button>
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
        </form>
      </>
    );
  }
}

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  // return Math.floor(Math.random() * (max - min + 1)) + min;
  return Math.floor(secureRandomNumber() * (max - min + 1)) + min;
}

ManualBet.propTypes = {
  coin: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
});

export default connect(mapStateToProps, { gameCoin, setWallet })(ManualBet);
