import React, { useEffect } from "react";
import $ from "jquery";
import rangeslider from "rangeslider.js";
import { wait, isLogged } from "../../../Helper";

class Estimator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      result: 1500,
      percent: 15,
      loading: false,
    };
  }

  componentDidMount() {

    wait(1000).then(() => {
      this.setupRange();
      this.setState({ loading: false });
    });
  }

  setupRange() {
    var self = this;

    $("#range_d").rangeslider({
      polyfill: false,

      // Callback function
      onInit: function (e) {},

      // Callback function
      onSlide: function (position, value) {
        // 15%
        let percent = 15;

        var calculate = (value * percent) / 100;
        self.setState({ result: value, percent: calculate });
      },

      // Callback function
      onSlideEnd: function (position, value) {},
    });

    wait(1500).then(() => {
      var r = $("#range_d");
      r.val(1500).change();

      if (!isLogged) {
        var w = $(".progress-bar").css("width");
        $(".progress-bar").css("width", parseFloat(w) + 5);
      }
    });
  }

  componentWillUnmount() {
    $("#range_d").rangeslider("destroy");
  }

  render() {
    return (
      <>
        {!this.state.loading ? (
          <>
            <div class="progress range rev">
              <div
                class="progress-bar"
                style={{ width: this.state.result / 50 + "%" }}
              ></div>

              <input
                type="range"
                id="range_d"
                min="1"
                max={"5000"}
                value={"1"}
                step="1"
                data-rangesliders
              />
            </div>

            <p className="font-14 text-warning mt-3 estimatordesc">
              From Each Deposit: {this.state.result} USD you{" "}
              <span className="text-purple-affiliate">
                will Earn: {this.state.percent} USD
              </span>
            </p>
          </>
        ) : (
          <>Please Wait ...</>
        )}
      </>
    );
  }
}

export default Estimator;
