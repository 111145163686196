import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import Index from "./App/Pages";
import Home from "./App/Pages/Parts/Home";
import Game from "./App/Pages/Parts/Home/Games"
import Favorites from './App/Pages/Parts/Home/Favorites';
import NotFound from "./App/Pages/404";
import SlotEnd from "./App/Pages/SlotEnd";
import Affiliate from "./App/Pages/Affiliate";
import BonusAff from "./App/Pages/Affiliate/Bonus";
import Bonus from "./App/Pages/Bonus";
import Contest from "./App/Pages/Contest";
import Promotion from "./App/Pages/Promotions";
import Giveaway from "./App/Pages/Giveaway";
import AboutUs from "./App/Pages/AboutUs";
import Rules from "./App/Pages/Rules";
import ReportBug from "./App/Pages/ReportBug";
import Blog from "./App/Pages/Blog";
import Blogs from "./App/Pages/Blogs";
import OneBlog from "./App/Pages/Blogs/OneBlog";

import Login from "./App/Pages/Auth/Login";
import Register from "./App/Pages/Auth/Register";
import UserStat from "./App/Components/User/Stat/Modal";
import GameStat from "./App/Components/Game/Stat";
import UserSetting from "./App/Components/User/Setting";
import GamesHome from "./App/Games";

import Search from "./App/Slots/Search";
import SlotsHome from "./App/Slots/index";
import Slots from "./App/Slots/Slots";
import Slot from "./App/Slots/Game";
import SportsHome from "./App/Sports";
import Sport from "./App/Sports/Sport";

import Crash from "./App/Games/_Crash";
import Keno from "./App/Games/Keno";
import Wheel from "./App/Games/Wheel";
import Limbo from "./App/Games/Limbo";
import Mine from "./App/Games/Mine";
import Hilo from "./App/Games/Hilo";
import Hashdice from "./App/Games/HashDice";
import ClassicDice from "./App/Games/ClassicDice";
import SingleKeno from "./App/Games/SingleKeno";
import Plinko from "./App/Games/Plinko2";
import VideoPoker from "./App/Games/VideoPoker";
import Blackjack from "./App/Games/BlackJack2";
import Goal from "./App/Games/Goal";
import Roulette from "./App/Games/Roulette2";
import SnackAndLadders from "./App/Games/SnakeAndLadders";
import ThreeCardMonte from "./App/Games/ThreeCardMonte";
import MagicWheel from "./App/Games/MagicWheel";
import Baccarat from "./App/Games/Baccarat";
import Diamond from "./App/Games/Diamond";
import CryptoTrading from "./App/Games/CryptoTrading";
import VipLevel from "./App/Pages/VipLevel";
import VerEmailResult from "./App/Verification";

function Router(props) {
    let location = useLocation();
    let background = location.state && location.state.background;
    let loc = location.pathname;
    const { t } = props;
    return (
        <>
            <Switch history={props.history} location={background || location}>
                <Route exact path="/" children={<Index t={t} searchbar={true} location={loc} content={<Home t={t} />} />} />
                <Route  path="/resetpass" children={<Index t={t} searchbar={true} location={loc} content={<Home t={t} />} />} />
                <Route path="/register" children={<Index t={t} content={<Home t={t} content={<Register t={t} history={props.history} show={true} />} />} />} />
                <Route path="/login" children={<Index t={t} content={<Home t={t} content={<Login t={t} history={props.history} show={true} />} />} />} />
                <Route path="/setting" children={<Index t={t} content={<UserSetting t={t} history={props.history} />} />} />
                <Route path="/statistics" children={<Index t={t} content={<UserSetting t={t} history={props.history} />} />} />
                <Route path="/affiliate" children={<Index t={t} content={<Affiliate t={t} history={props.history} />} />} />
                <Route path="/aff" children={<Index t={t} content={<BonusAff params={location} history={props.history} t={t} />} />} />
                <Route path="/rules" children={<Index t={t} content={<Rules params={location} history={props.history} t={t} />} />} />
                <Route path="/report-bug" children={<Index t={t} content={<ReportBug params={location} history={props.history} t={t} />} />} />
                <Route path="/help-center" children={<Index t={t} content={<Blog params={location} history={props.history} t={t} />} />} />
                <Route path="/user/:id" children={<Index t={t} content={<Home t={t} content={<UserStat t={t} history={props.history} params={location} byLink={true} />} />} />} />
                <Route path="/game/:id" children={<Index t={t} content={<GameStat t={t} params={location} />} />} />
                <Route path="/crash" children={<Index t={t} content={<Crash t={t} gid={1} />} />} />
                <Route path="/keno" children={<Index t={t} content={<Keno t={t} gid={4} />} />} />
                <Route path="/wheel" children={<Index t={t} content={<Wheel t={t} gid={7} />} />} />
                <Route path="/limbo" children={<Index t={t} content={<Limbo t={t} gid={17} />} />} />
                <Route path="/classic-dice" children={<Index t={t} content={<ClassicDice t={t} gid={2} history={props.history} />} />} />
                <Route path="/mine" children={<Index t={t} content={<Mine t={t} gid={3} history={props.history} />} />} />
                <Route path="/singlekeno" children={<Index t={t} content={<SingleKeno t={t} gid={10} history={props.history} />} />} />
                <Route path="/roulette" children={<Index t={t} content={<Roulette t={t} gid={6} history={props.history} />} />} />
                <Route path="/plinko" children={<Index t={t} content={<Plinko t={t} gid={12} />} />} />
                <Route path="/videopoker" children={<Index t={t} content={<VideoPoker t={t} gid={11} />} />} />

                {/* <Route path="/hash-dice" children={<Index t={t} content={<Hashdice t={t} gid={19} />} />} />*/}
                {/* <Route path="/hilo" children={<Index t={t} content={<Hilo t={t} gid={9} history={props.history} />} />} /> */}
                {/* <Route path="/baccarat" children={<Index t={t} content={<Baccarat t={t} gid={18} history={props.history} />} />} /> */}
                {/* <Route path="/blackjack" children={<Index t={t} content={<Blackjack t={t} gid={5} history={props.history} />} />} /> */}
                {/* <Route path="/goal" children={<Index t={t} content={<Goal t={t} gid={13} history={props.history} />} />} /> */}

                <Route path="/snake-and-ladders" children={<Index t={t} content={<SnackAndLadders t={t} gid={14} history={props.history} />} />} />
                <Route path="/3-card-monte" children={<Index t={t} content={<ThreeCardMonte t={t} gid={15} history={props.history} />} />} />
                <Route path="/magic-wheel" children={<Index t={t} content={<MagicWheel t={t} gid={8} history={props.history} />} />} />
                <Route path="/diamond" children={<Index t={t} content={<Diamond t={t} gid={16} history={props.history} />} />} />
                <Route path="/crypto-trading" children={<Index t={t} content={<CryptoTrading t={t} gid={16} history={props.history} />} />} />
                <Route path="/slots/:id" children={<Index t={t} content={<Slot params={location} t={t} history={props.history} />} />} />
                <Route path="/slots" children={<Index t={t} searchbar={true} content={<SlotsHome params={location} t={t} />} />} />
                <Route path="/favourites" children={<Index t={t} content={<Favorites specific='favourites' t={t} history={props.history} />} />} />
                <Route path="/games" children={<Index t={t} content={<GamesHome t={t} />} />} />
                <Route path="/live" children={<Index t={t} searchbar={true} content={<SlotsHome t={t} live={true} />} />} />
                <Route path="/rakeback" children={<Index t={t} content={<Bonus t={t} />} />} />
                <Route path="/daily-contest" children={<Index t={t} content={<Contest t={t} />} />} />
                <Route path="/promotion" children={<Index t={t} content={<Promotion t={t} />} />} />
                <Route path="/giveaway" children={<Index t={t} content={<Giveaway t={t} />} />} />
                <Route path="/about-us" children={<Index t={t} content={<AboutUs t={t} />} />} />
                <Route path="/verify-email/:token" children={<Index t={t} content={<VerEmailResult t={t} params={location} />} />} />
                {/* <Route path="/sports/:id" children={<Index t={t} content={<Sport t={t} history={props.history}/>} />} /> */}
                <Route path="/sports" children={<Index t={t} sports={true} content={<SportsHome t={t} />} />} />
                <Route path="/vip-levels" children={<Index t={t} content={<VipLevel t={t} />} />} />
                <Route path="/blog" children={<Index t={t} content={<Blogs params={location} history={props.history} t={t} />} />} />
                {/* <Route path="/:title/:id" children={<Index t={t} content={<OneBlog params={location} history={props.history} t={t} />} />} /> */}
                {/* <Route path="/:search/:name" children={<Index t={t} content={<Search params={location} history={props.history} t={t} />} />} /> */}
                <Route path="/:provider/:name" children={<Index t={t} searchbar={true} content={<SlotsHome params={location} t={t} />} />} />


                <Route path="/slot-end">
                    <SlotEnd/>
                </Route>
                <Route path="*">
                    <NotFound history={props.history} />
                </Route>
            </Switch>
        </>
    );
}

export default Router;
