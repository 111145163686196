import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Col, Row, InputGroup, Form } from "react-bootstrap";
import storage from "../../../Storage";
import RangeCredit from "../../Components/Game/Addons/Range";
import { gameCoin } from "../../../actions/gameCoin";
import { setWallet } from "../../../actions/gameWallet";
import {
  Event,
  __,
  forceSatoshiFormat,
  isValidNumber,
  sendNotfication,
  wait,
  isMobile,
  funPlay,
  playAudio,
  isLogged,
  defaultBetAmount,
  getDefaultBetAmount,
} from "../../../Helper";

class Bet extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      engine: this.props.engine,
      started: false,
      bet: "manual",
      buttonText: "Bet",
      amount: defaultBetAmount(this.props.coin),
      inputDisabled: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBet = this.handleBet.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.outRef = React.createRef();
  }

  componentDidMount() {
    if (this._isMounted) {
      let { engine } = this.state;

      wait(300).then(() => {
        if (isMobile()) {
          this.setState({ padding: "p-1" });
        }
      });

      this.props.gameCoin();
      engine.on("play", (data) => this.play(data));
      engine.on("busted", (data) => this.busted(data));
      engine.on("error", (data) => this.error(data));
      engine.on("stop_playing", () => this.stopManual());
      document.addEventListener("mousedown", this.handleClickOutside);
    }
  }

  componentWillMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    let { engine } = this.state;
    engine.init = false;
    this._isMounted = false;
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.outRef && !this.outRef.current.contains(event.target)) {
      let value = parseFloat(this.state.amount);

      if (!isValidNumber(value)) {
        return this.setState({ amount: 0.1 });
      }
      value = __.clamp(getDefaultBetAmount(this.props.coin), 0.001, value);
      value = forceSatoshiFormat(value);
      if (!__.isNaN(value)) this.setState({ amount: value });
    }
  }

  stopManual() {
    if (this._isMounted) {
      this.setState({
        started: false,
        buttonText: "Bet",
        inputDisabled: false,
      });
    }
  }

  error(data) {
    if (this._isMounted) {
      sendNotfication(data.message, "info", "top-center");

      if (data.code === "credit") this.props.setWallet(true, data.uid);

      this.setState({
        inputDisabled: false,
        started: false,
        buttonText: "Bet",
      });
    }
  }

  play(data) {
    if (this._isMounted) {
      // for back on the game, engine should get values:

      let { engine, amount } = this.state;
      engine.amount = amount;
      engine.coin = this.props.coin;

      this.setState({ inputDisabled: true });
      playAudio("dice2.mp3");
    }
  }

  busted(data) {
    if (this._isMounted) {
      this.setState({ inputDisabled: false });
    }
  }

  placeBet() {
    if (this._isMounted) {
      let { engine, amount } = this.state;
      wait(500).then(() => {
        engine.init = true;
        engine.amount = amount;
        engine.coin = this.props.coin;
        engine.start();
      });
    }
  }

  isCorrectBet(amount) {
    if (this._isMounted) {
      if (__.isUndefined(amount)) return;

      if (amount === "") return false;

      if (!isValidNumber(amount)) return false;

      if (parseFloat(amount) <= 0) {
        this.setState({ amount: forceSatoshiFormat(0.001) });
      }

      return true;
    }
  }

  handleBet(e) {
    if (this._isMounted) {
      e.preventDefault();

      let { amount } = this.state;

      // Check logged user
      if (!isLogged) {
        return Event.emit("showAuthModal", true);
      }

      // Check is Correct Bet
      if (!this.isCorrectBet(amount)) return false;

      this.setState({ inputDisabled: true });
      this.placeBet();
    }
  }

  handleInputChange(event) {
    if (this._isMounted) {
      let target = event.target;
      let value = target.value;
      let name = target.name;

      this.setState({ [name]: value });
    }
  }

  setAmount = (value) => {
    if (!this.state.inputDisabled) {
      if (!__.isNaN(value)) {
        this.setState({ amount: value });
        storage.setKey("lam", value);
      }
    }
  };

  render() {
    let { bet, inputDisabled, started, padding } = this.state;

    return (
      <>
        {this._isMounted && (
          <>
            <form
              className="w-100 mt-1"
              onSubmit={(e) => {
                this.handleBet(e);
              }}
            >
              <Row>
                <Col sm={12} className={"d-md-none d-sm-block"}>
                  {bet === "manual" && (
                    <Button
                      variant={"btn btn-xs btn-block btn-bet py-2 mb-2"}
                      disabled={inputDisabled}
                      type="submit"
                    >
                      {this.state.buttonText}
                    </Button>
                  )}
                </Col>

                <Col xl={6} md={7}>
                  <InputGroup className="mb-1">
                    <InputGroup.Text>
                      <img
                        src={
                          "/assets/images/" +
                          __.toLower(this.props.coin) +
                          ".png"
                        }
                        className={"mini-coin-7"}
                        alt=""
                      />
                      BET
                    </InputGroup.Text>
                    <Form.Control
                      ref={this.outRef}
                      disabled={inputDisabled}
                      className="form-control"
                      type="text"
                      id="amount"
                      name="amount"
                      value={this.state.amount}
                      autoComplete={"off"}
                      onKeyUp={this.handleInputChange}
                      onChange={this.handleInputChange}
                    ></Form.Control>
                    <InputGroup.Text>
                      <RangeCredit
                        set={this.setAmount}
                        amount={this.state.amount}
                      />
                    </InputGroup.Text>
                  </InputGroup>
                </Col>

                <Col xl={6} md={5}>
                  <InputGroup className="mb-1">
                    <InputGroup.Text>
                      <img
                        src={
                          "/assets/images/" +
                          __.toLower(this.props.coin) +
                          ".png"
                        }
                        className={"mini-coin-7"}
                        alt=""
                      />
                      WIN AMOUNT
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      disabled={true}
                      readOnly={true}
                      className="form-control"
                      value={forceSatoshiFormat(this.state.amount / 2)}
                    ></Form.Control>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col md={5} sm={12} className="m-auto hidden-sm">
                  <div className={"form-group rev mb-1"}>
                    <Button
                      variant={"btn btn-md btn-block mt-2 btn-bet"}
                      disabled={inputDisabled}
                      type="submit"
                    >
                      {this.state.buttonText}
                    </Button>
                  </div>
                </Col>
              </Row>
            </form>
          </>
        )}
      </>
    );
  }
}

Bet.propTypes = {
  coin: PropTypes.string,
  credit: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
  credit: state.items.credit,
});

export default connect(mapStateToProps, { gameCoin, setWallet })(Bet);
