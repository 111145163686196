import React, { Component, createRef } from "react";
import { withRouter } from "react-router-dom";
import { Form, Modal, Row, Col } from "react-bootstrap";
import Cookies from "js-cookie";
import axios from "axios";
// import ReCAPTCHA from "react-google-recaptcha";
import storage from "../../../Storage";
import GoogleBtn from "./Google";
import Forget from "./Forget";
import socket from "../../../Socket";
import {
  Event,
  wait,
  decode,
  encode,
  sendNotfication,
  RECAPTCHA,
  API,
  isLogged,
  SITE,
} from "../../../Helper";
import C from "../../../Constant";
import { useTranslation, withTranslation } from "react-i18next";

class LoginIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: this.props.show ? this.props.show : false,
      forgetPage: false,
      twoFaPage: false,
      disabled2: false,
      twoFa: null,
      token: "",
      username: "",
      password: "",
      // recaptcha: false,
      status: false,
      submitted: false,
      disabled: false,
      effect: "zoomIn",
      passwordType: "password",
      eye: "fa fa-eye",
      err_credential: false,
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    // this.recaptchaRef = createRef();
  }

  componentDidMount() {
    if (isLogged) {
      return this.handleClose()
    }
    
    socket.on(C.TWO_FA, (data) => this.setupTwoFa(decode(data)));
    socket.on(C.TWO_FA_CONFIRM, (data) => this.confirmTwoFa(decode(data)));
    Event.on("login_form", () => {
      this.back();
      this.handleShow();
    });

    Event.on("login", (data) => this.setLogin(data));

    window.addEventListener("message", this.handleMessage);
  }

  componentWillUnmount() {
    socket.off(C.LOGIN_USER);
    socket.off(C.TWO_FA);
    socket.off(C.TWO_FA_CONFIRM);

    window.removeEventListener("message", this.handleMessage);
  }

  handleMessage = (event) => {
    if (event.origin !== "https://api.betsmixer.com") return;

    const { oauth_type, oauth_id, name, email, ok } = event.data;

    if (ok) {
      console.log(oauth_type, oauth_id, name, email);
      this.setState({ oauth_id, oauth_type });

      // wait(200).then(() => {
      //   socket.emit(
      //     C.LOGIN_USER,
      //     encode({
      //       oauth_id: oauth_id,
      //       oauth_type: oauth_type,
      //       username: name,
      //       password: null,
      //       recaptcha: "google",
      //     })
      //   );
      // });

      var self = this;

      axios
        .post(API + "/login", {
          oauth_id: oauth_id,
          oauth_type: oauth_type,
          username: name,
          password: null,
          recaptcha: "google",
        })
        .then(function (response) {
          self.setLogin(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  setLogin = (data) => {
    if (data.status === true) {
      this.logInSuccess(data);
    } else {
      this.setState({ submitted: false, disabled: false });
      if (data.status == "User was banned") {
        let msg = this.props.t("your_account_has_banned_etc") + ".";
        sendNotfication(msg, "success", "bottom-left");
      } else if (data.status == "Username Not Found") {
        this.setState({ err_credential: true });
        document.getElementById("login_pass").focus();
      } else if (data.status == "Invalid credentials") {
        this.setState({ err_credential: true });
        document.getElementById("login_pass").focus();
      } else if (data.status == "2fa") {
        this.setState({ twoFaPage: !this.state.twoFaPage });
        // sendNotfication(data.status, "success", "bottom-left");

        this.setState({ token: data.token });
        wait(50).then(() => {
          socket.emit(C.TWO_FA, encode({ token: data.token }));
        });
      }
    }
  };

  logInSuccess(data) {
    if(data.ban){
      return sendNotfication(
        this.props.t("You are banned"),
        "success",
        "bottom-left"
      );
    }

    sendNotfication(
      this.props.t("successfully_login"),
      "success",
      "bottom-left"
    );
    Cookies.set("session", data.token, { expires: 14 });
    storage.setKey("uid", data.uid);
    storage.setKey("logged", true);
    storage.setKey("token", data.token);
    storage.setKey("hide", data.hide);
    storage.setKey("name", data.name);
    storage.setKey("avatar", data.avatar);
    storage.setKey("email", data.email);
    storage.setKey("credit", data.credit);
    storage.setKey("room", data.room);
    storage.setKey("friends", data.friends);
    storage.setKey("usd", true);
    storage.setKey("coin", data.coin?.toUpperCase() || "USDT");
    storage.setKey("mod", data.mod);
    storage.setKey("vip_level", data.vip_level);
    data.first_name && storage.setKey("first_name", data.first_name);
    data.last_name && storage.setKey("last_name", data.last_name);
    data.address && storage.setKey("address", data.address);
    data.city && storage.setKey("city", data.city);
    data.country && storage.setKey("country", data.country);
    data.zip_code && storage.setKey("zip_code", data.zip_code);
    data.affilate_parent &&
      storage.setKey("affilate_parent", data.affilate_parent);
    let username =
      data.first_name && data.last_name
        ? data.first_name + " " + data.last_name
        : data.name;
    window.Intercom("boot", {
      app_id: C.INTERCOM_APP_ID,
      name: username,
    });
    wait(500).then(() => {
      // window.location.reload();
      window.location.assign(SITE + location.pathname);
    });
  }

  setupTwoFa(qr) {
    this.setState({ twoFa: qr });
  }

  handleShow(e) {
    this.setState({ show: true, effect: "zoomIn" });
  }

  handleClose() {
    this.setState({
      show: false,
      effect: "zoomOut",
      disabled: false,
      forgetPage: false,
      twoFaPage: false,
      status: false,
      submitted: false,
      token: "",
      username: "",
      password: "",
      err_credential: false,
    });
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({
      submitted: true,
      disabled: true,
      status: this.props.t("please_wait"),
    });

    const { username, password } = this.state;
    if (!(username && password)) {
      this.setState({ disabled: false, status: false });
      return;
    }

    //Execute Recaptcha Token
    // const token = await this.recaptchaRef.current.executeAsync();
    // wait(200).then(() => {
    //   socket.emit(
    //     C.LOGIN_USER,
    //     encode({
    //       oauth_id: null,
    //       oauth_type: null,
    //       username: username,
    //       password: password,
    //       recaptcha: "google",
    //     })
    //   );
    // });

    var self = this;

    axios
      .post(API + "/login", {
        oauth_id: null,
        oauth_type: null,
        username: username,
        password: password,
        recaptcha: "google",
      })
      .then(function (response) {
        self.setLogin(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  confirm = (e) => {
    e.preventDefault();
    console.log("this.state.verify_code", this.state.verify_code);
    console.log("this.state.password", this.state.password);
    console.log("this.state.token", this.state.token);
    if (!this.state.verify_code || !this.state.password || !this.state.token)
      return;
    this.setState({ disabled2: true });
    socket.emit(
      C.TWO_FA_CONFIRM,
      encode({
        code: this.state.verify_code,
        password: this.state.password,
        token: this.state.token,
      })
    );
  };

  confirmTwoFa(data) {
    this.setState({ disabled2: false });

    if (data.status !== true) {
      return sendNotfication(this.props.t(data.status.toString()));
    }

    this.logInSuccess(data);

    sendNotfication(this.props.t("2fa_successfully_activated"));
  }

  forgetPass = (e) => {
    this.setState({ forgetPage: !this.state.forgetPage });
  };

  showPassword = (e) => {
    e.preventDefault();
    let type = this.state.passwordType === "text" ? "password" : "text";
    let eye =
      this.state.passwordType === "text" ? "fa fa-eye" : "fa fa-eye-slash";
    this.setState({ passwordType: type, eye: eye });
  };

  // recaptchaChange = (value) => {
  //     this.setState({ recaptcha: value });
  // };

  register = (value) => {
    this.handleClose();
    Event.emit("register_form");
  };

  back = (value) => {
    this.setState({ forgetPage: false });
  };

  handleSignInSteam = () => {
    const popupWindow = window.open(
      "https://api.betsmixer.com/auth/steam",
      "_blank",
      "width=800, height=600"
    );
    if (window.focus) popupWindow.focus();
  };

  handleSignInTwitch = () => {
    const popupWindow = window.open(
      "https://api.betsmixer.com/auth/twitch",
      "_blank",
      "width=800, height=600"
    );
    if (window.focus) popupWindow.focus();
  };

  handleSignInGoogle = () => {
    const popupWindow = window.open(
      "https://api.betsmixer.com/auth/google",
      "_blank",
      "width=800, height=600"
    );
    if (window.focus) popupWindow.focus();
  };

  handleSignInTwitter = () => {
    const popupWindow = window.open(
      "https://api.betsmixer.com/auth/twitter",
      "_blank",
      "width=800, height=600"
    );
    if (window.focus) popupWindow.focus();
  };

  handleSignInApple = () => {
    const popupWindow = window.open(
      "https://api.betsmixer.com/auth/apple",
      "_blank",
      "width=800, height=600"
    );
    if (window.focus) popupWindow.focus();
  };

  render() {
    const { t, history, location, match } = this.props;
    return (
      <>
        <button
          className={"btn btn-transparent btn-round no-shadow text-white"}
          onClick={(e) => this.handleShow(e)}
        >
          {t("login")}
        </button>
        <Modal
          size="md"
          centered={true}
          backdrop="static"
          show={this.state.show}
          onHide={this.handleClose}
          aria-labelledby="login-md-modal"
          className={"modalAuth modalAuthLogin animated " + this.state.effect}
        >
          <Modal.Header>
            {this.state.forgetPage && (
              <button
                onClick={this.back}
                className="btn btn-transparent backbutton cpt hvw p-0"
              >
                <i className="fa fa-chevron-left fonts-18 aligdsn-top mr-3 mt-1" />
              </button>
            )}
            <button
              type="button"
              className="close p-2"
              onClick={this.handleClose}
            >
              <i className={"mdi mdi-close"} />
            </button>
          </Modal.Header>
          <Modal.Body className="auth-modal p-0">
            <div className="m-auto">
              {!this.state.forgetPage && (
                <div>
                  <div className="text-center">
                    {/*{this.state.recaptcha}*/}
                    <img
                      src="/assets/images/betsmixer-g.svg"
                      className={"img-fluid auth-logo"}
                      alt="Logo"
                    />
                  </div>
                  <div className="px-4 mainlogin">
                    {!this.state.twoFaPage ? (
                      <>
                        <form
                          className="form-horizontal auth-form mt-4"
                          onSubmit={(e) => {
                            this.handleSubmit(e);
                          }}
                        >
                          <div className="form-group mb-2">
                            <div className="input-group">
                              <div className="input-group-append">
                                <span className="input-group-text bgp">
                                  {t("username")}
                                </span>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                // value={this.state.username}
                                onChange={(e) => {
                                  this.setState({ username: e.target.value });
                                }}
                                style={{ height: 40 }}
                                autoComplete="on"
                                placeholder="Your email or username"
                              />
                              {this.state.submitted && !this.state.username && (
                                <div className="help-block help-block-main">
                                  {t("username_is_required")}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-2">
                            <div className="input-group">
                              <div className="input-group-append">
                                <span className="input-group-text bgp">
                                  {t("password")}
                                </span>
                              </div>
                              <input
                                id="login_pass"
                                type={this.state.passwordType}
                                className="form-control"
                                // value={this.state.password}
                                autoComplete="off"
                                onChange={(e) => {
                                  this.setState({ password: e.target.value });
                                }}
                                style={{ height: 40 }}
                                placeholder="Your password"
                              />
                              <div className="input-group-text pr-0 eye-button">
                                <button
                                  onClick={this.showPassword}
                                  className="btn btn-transparent"
                                >
                                  <i className={this.state.eye} />
                                </button>
                              </div>
                            </div>
                            {this.state.submitted && !this.state.password && (
                              <div className="help-block">
                                {t("password_is_required")}
                              </div>
                            )}
                            {this.state.err_credential && (
                              <div className="help-block">
                                {t("your_credential_is_incorrect")}
                              </div>
                            )}
                          </div>
                          <div className="text-center">
                            <button
                              className="btn btn-auth btn-block font-weight-bold no-shadow buttonlogin"
                              disabled={this.state.disabled}
                            >
                              {this.state.disabled && (
                                <span
                                  className="spinner-grow spinner-grow-sm mr-1"
                                  role="loading"
                                ></span>
                              )}
                              <i className="mdi mdi-login mr-1 float-left font-18" />{" "}
                              {t("login_to_site")}
                            </button>
                          </div>
                          <div className="mt-3 d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                              <img
                                src="/assets/images/footer/footer-i1.svg"
                                alt="logo"
                              />
                              <a
                                href="#"
                                className="ms-2 opacity-75"
                                onClick={this.register}
                              >
                                {t("or_register_new_account")}
                              </a>
                            </div>
                            <button
                              onClick={this.forgetPass}
                              className="resetpassword"
                            >
                              <i className="mdi mdi-information mr-1" />
                              {t("rest_password")}
                            </button>
                          </div>
                          {/* <ReCAPTCHA ref={this.recaptchaRef} size="invisible" sitekey={RECAPTCHA} /> */}
                        </form>
                        <div className="text-center mt-3 mb-2 sociallogintitle">
                          <span>— Or login with —</span>
                        </div>
                        <div className="d-flex justify-content-between mb-4 sociallogin">
                          {/*<button onClick={this.handleSignInSteam} className="btn d-flex justify-content-center steambutton">
                            <i className="mdi mdi-steam mr-1 float-left font-18" />
                            <span>Steam</span>
                          </button>
                           <button onClick={this.handleSignInTwitch} className="btn d-flex justify-content-center">
                            <i className="mdi mdi-twitch mr-1 float-left font-18" />
                            <span>Twitch</span>
                          </button> */}
                          <button
                            onClick={this.handleSignInGoogle}
                            className="btn d-flex justify-content-center gmailbtn"
                          >
                            <i className="mdi mdi-google mr-1 float-left font-18" />
                            <span>Gmail</span>
                          </button>
                          <button
                            onClick={this.handleSignInTwitter}
                            className="btn d-flex justify-content-center twitterbtn"
                          >
                            <i className="mdi mdi-twitter mr-1 float-left font-18" />
                            <span>Twitter</span>
                          </button>
                          <button
                            onClick={this.handleSignInApple}
                            className="btn d-flex justify-content-center applebtn"
                          >
                            <i className="mdi mdi-apple mr-1 float-left font-18" />
                            <span>Apple</span>
                          </button>
                        </div>
                      </>
                    ) : (
                      <Form onSubmit={this.confirm}>
                        <Row>
                          <Col sm="12" md="12">
                            <Row>
                              <Col sm="12" md="12" className="text- m-auto">
                                <p className="font-14 text-white">
                                  {/* {t('security_authentication_t_1')} */}
                                  {t(
                                    "to_continue_please_enter_6_digit_verification_code_generated_etc"
                                  )}
                                </p>
                                <div className="text-center">
                                  {this.state.twoFa ? (
                                    <img
                                      className="img-fluid rounded border p-1"
                                      alt="2FA"
                                      src={this.state.twoFa}
                                      style={{
                                        width: 150,
                                        background: "white",
                                      }}
                                    />
                                  ) : (
                                    <span
                                      className="spinner-grow spinner-grow-sm mr-1"
                                      role="loading"
                                    ></span>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col sm="12">
                                <label>{t("verification_code")}</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={this.state.verify_code}
                                  onChange={(e) =>
                                    this.setState({
                                      verify_code: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                              <Col sm="12" md="12" className="text-center">
                                <button
                                  type="submit"
                                  className="btn btn- mt-3 btn-purple px-4 no-shadow"
                                  disabled={this.state.disabled2}
                                >
                                  {t("verify")}
                                  {this.state.disabled2 && (
                                    <>
                                      <div
                                        className={
                                          "ml-2 spinner-border spinner-border-sm"
                                        }
                                      />
                                    </>
                                  )}
                                </button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="text-center mb-4">
                          <Col md="6" className="my-1"></Col>
                        </Row>
                      </Form>
                    )}
                  </div>
                </div>
              )}
              {this.state.forgetPage && (
                <Forget t={t} handleClose={this.handleClose} />
              )}
            </div>
          </Modal.Body>
          <img
            src="/assets/images/login.jpg"
            alt="logo"
            className="banner-login"
          />
        </Modal>
      </>
    );
  }
}

export default withRouter(withTranslation()(LoginIndex));
