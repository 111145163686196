import React, { Component } from "react";
import { Button, Row, Col, Dropdown, Badge, Stack } from "react-bootstrap";
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import socket from "../../../../Socket";
import { 
  __,
  decode, 
  encode, 
  isValidNumber, 
  forceSatoshiFormat, 
  removeTrailingZeros, 
  usdToCrypto, 
  wait, 
  sendNotfication, 
  Event, 
  API,
  toUSDFormat,
  isMobile
} from "../../../../Helper";
import storage from "../../../../Storage";
import coins from "../../../coins_";
import WithdrawlArchive from "./WithdrawlArchive";
import C from "../../../../Constant";
import { t } from "i18next";
import axios from "axios";
//_isMounted can prevent from double socket response

const FEE_WITHDRAWL = forceSatoshiFormat(0.000001);

class Withdrawl extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      credit: storage.getKey("credit"),
      credits: {},
      showArchive: false,
      slide: false,
      wallet: "",
      amount: 0,
      im: true,
      error: false,
      error_minimum: false,
      error2: false,
      loading: false,
      loader: false,
      list: [],
      coin: storage.getKey("coin"),
      fee_withdrawal: FEE_WITHDRAWL,
      withdrawal_fee_percent: 0,
      withdrawal_min: 0,
      withdrawal_threshold: 0,
      value: 0,
      isKycVerified: 1,
      selectedPassport: null,
      selectedFace: null,
      p_loaded: null,
      f_loaded: null,
      p_uploaded: null,
      f_uploaded: null,
      isKycRequired: 0,
      affilate: 0,
      targetAmount: 0,
      isRequestAvailable: true,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.setMaxBits = this.setMaxBits.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    //------ KYC disabled ----------//
    // socket.emit(C.GET_KYC_REQUIRED);
    // socket.on(C.GET_KYC_REQUIRED, (data) => {
    //   if (decode(data) == 1) {
    //     this.setState({isKycRequired: decode(data)});
    //     socket.emit(C.GET_KYC_ABLE);
    //     socket.on(C.GET_KYC_ABLE, (data) => {
    //       let kyc_verified = decode(data).kyc_verified;
    //       this.setState({isKycVerified: kyc_verified});
    //     });
    //   }
    // });
    socket.emit(C.GET_AFFILATE);
    socket.on(C.GET_AFFILATE, (data) => {
      let { affilate, users } = decode(data);
      let amount = toUSDFormat(affilate);
      this.setState({affilate: amount});
    });
    socket.emit(C.GET_USER_PROFIT);
    socket.on(C.GET_USER_PROFIT, data => {
        this.getCredit(decode(data))
    });
    socket.emit(C.IS_REQUEST_AVAILABLE);
    socket.on(C.IS_REQUEST_AVAILABLE, data => {
      let { isRequestAvailable } = decode(data);
      this.setState({ isRequestAvailable });
    });
    // socket.emit(C.GET_KYC_ABLE);
    // socket.on(C.GET_KYC_ABLE, (data) => {
    //   let kyc_verified = decode(data).kyc_verified;
    //   this.setState({isKycVerified: kyc_verified});
    // });
    socket.emit(C.CREDIT_COIN, encode({ coin: this.state.coin }));
    socket.on(C.CREDIT_COIN, (data) => this.setCreditCoin(decode(data)));
    socket.on(C.SUBMIT_NEW_WITHDRAWL, (data) => this.response(decode(data)));

    Event.on("withdraw_archive", () => {
      this.showArchive();
    });
  }

  getCredit(data) {
    let { credit } = data;
    this.setState({ credits: credit });
    coins.forEach((item, key) => {
      if (item.preffix === "NC") return;
      let list = (
        <Dropdown.Item
          key={key}
          onClick={(e) => this.handleInputCoin(item.active, item.preffix)}
          className={"animated fadeIn"}
        >
          <img
            src={"/assets/images/" + item.image}
            className={"mini-coin-8"}
            alt=""
          />
          { __.includes(item.preffix, '_') ?  __.split(item.preffix, '_')[0] : item.preffix } ({item.preffix != "AFF" ? forceSatoshiFormat(this.state.credits[__.toLower(item.preffix)], item.preffix, true) : forceSatoshiFormat(this.state.affilate, 'USDT', true)})
        </Dropdown.Item>
      );
      this.setState((state) => ({ list: [list, ...state.list] }));
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
    socket.off(C.GET_KYC_ABLE);
    socket.off(C.CREDIT_COIN);
    socket.off(C.SUBMIT_NEW_WITHDRAWL);
    socket.off(C.GET_KYC_REQUIRED);
    socket.off(C.GET_AFFILATE);
    socket.off(C.GET_USER_PROFIT);
  }

  response(data) {
    if (this._isMounted) {
      wait(1000).then(() => {
        let message = data.status.toString();
        sendNotfication(message, "info", "top-center");
        socket.emit(C.CREDIT);
        this.props.handleClose();
        this.setState({
          showArchive: false,
          loading: false,
        });
      });
    }
  }

  handleInputChange(event) {
    let target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    if (target.name === "coin") {
      socket.emit(C.CREDIT_COIN, encode({ coin: value }));
    }

    if (target.name === "amount") {
      let error = false, error_minimum = false;

      if (Number(value) > Number(this.maxUserCredit())) error = true;
      if (parseFloat(forceSatoshiFormat(value, this.state.coin, true)) < parseFloat(forceSatoshiFormat(this.state.withdrawal_min, this.state.coin, true))) error_minimum = true;
      this.setState({ error: error, error_minimum: error_minimum });
    }
    if (target.name === "amount") this.setState({ [target.name]: value, error3: false,  fee_withdrawal: value * this.state.withdrawal_fee_percent / 100});
    else this.setState({ [target.name]: value, error3: false });
  }

  getUserCredit() {
    return forceSatoshiFormat(this.state.credits[__.toLower(this.state.coin)]);
  }

  setCreditCoin(data) {
    let { credit, withdrawal_fee, withdrawal_min, withdrawal_threshold } = data;
    const targetAmount = removeTrailingZeros(usdToCrypto(withdrawal_min, this.state.coin));
    // document.getElementById('targetAmount').value = targetAmount;

    this.setState({ 
      credit: credit, 
      amount: removeTrailingZeros(usdToCrypto(withdrawal_min, this.state.coin)), 
      withdrawal_fee_percent: Number(withdrawal_fee), 
      fee_withdrawal: forceSatoshiFormat(targetAmount * Number(withdrawal_fee) / 100), 
      withdrawal_min: removeTrailingZeros(usdToCrypto(withdrawal_min, this.state.coin)),
      withdrawal_threshold: Number(withdrawal_threshold),
      targetAmount: targetAmount
    });
  }

  maxUserCredit() {
    this.setState({ error: false, error2: false });
    return this.getUserCredit();
  }

  setMaxBits() {
    // document.getElementById('targetAmount').value = this.maxUserCredit();
    this.setState({ targetAmount: this.maxUserCredit(), amount: this.maxUserCredit(), fee_withdrawal: forceSatoshiFormat(this.maxUserCredit() * this.state.withdrawal_fee_percent / 100)  });
  }

  submitForm(e) {
    if (this._isMounted) {

      e.preventDefault();

      let { error, coin, wallet, amount, credit, fee_withdrawal, withdrawal_threshold } = this.state;

      if (error === true) return

      if (!Number(amount) && !amount) return;


      if (wallet === "" || wallet.length < 10) {
        this.setState({ error3: t('please_enter_wallet_address') });
        return;
      }

      if (amount <= forceSatoshiFormat(0.0)) {
        this.setState({ error3: t('please_check_amount') });
        return;
      }
      
      var check = amount - fee_withdrawal;

      if (check <= 0.0) {
        this.setState({ error3: t('please_check_amount') });
        return;
      }

      let finalMoney = forceSatoshiFormat(amount - fee_withdrawal);

      if (__.toNumber(finalMoney) <= 0) {
        this.setState({ error3: t('please_check_amount') });
        return;
      }

      let restrecFee = true, minFee;

      //Check for minimum amount
      coins.forEach((item, key) => {
        if (__.toLower(item.preffix) === __.toLower(coin)) {
          let min = forceSatoshiFormat(item.min);
          finalMoney = __.toNumber(finalMoney);
          min = __.toNumber(min);
          // if (finalMoney < min) {
          //   minFee = forceSatoshiFormat(min);
          //   restrecFee = false;
          // }
        }
      });

      if (!restrecFee) {
        this.setState({ error2: true, minFee: minFee });
        return;
      }

      // const targetAmount = document.getElementById('targetAmount').value;
      if (parseFloat(forceSatoshiFormat(this.state.amount, this.state.coin, true)) < parseFloat(forceSatoshiFormat(this.state.withdrawal_min, this.state.coin, true))) return;

      // const approved = forceSatoshiFormat(amount, coin, true, false) < withdrawal_threshold ? 1 : 0;
      const approved = 0;
      
      this.setState({ loading: true });
      
      wait(500).then(() => {
        socket.emit(
          C.SUBMIT_NEW_WITHDRAWL,
          encode({
            coin: coin,
            wallet: wallet,
            amount: amount,
            immed: fee_withdrawal,
            approved
          })
        );
      });
    }
  }

  onChange(event, list) {
    this.setState({ list: list });
  }

  showArchive = (e) => {
    if (this.state.slide) Event.emit("withdraw_archive_back");

    this.setState({ slide: !this.state.slide });

    wait(500).then(() => {
      this.setState({ showArchive: !this.state.showArchive });
    });
  };

  handleInputCoin = (active, value) => {
    if(value != 'AFF') {
      if (active) {
        socket.emit(C.CREDIT_COIN, encode({ coin: value }));
      } else {
        this.setState({ amount: forceSatoshiFormat(0) });
      }
    } else {
      let aff = this.state.affilate;
      this.setState({ amount: forceSatoshiFormat(aff) });
    }
    this.setState({ coin: value, error: false, error2: false });
  };

  getFee = (coin) => {
    let find = __.find(coins, function (o) {
      return o.preffix === __.upperCase(coin);
    });

    return find.min;
  };

  handleChangeIndex = (index) => {
    this.setState({value: index});
  };

  onPassportFileChange = event => {
    let file = event.target.files[0];
    file.src = URL.createObjectURL(file);
    this.setState({ selectedPassport: file });
  };

  onFaceFileChange = event => {
    let file = event.target.files[0];
    file.src = URL.createObjectURL(file);
    this.setState({ selectedFace: file });
  };
  
  upload = (index) => {
    const formData = new FormData();
    if (index == 0) {
      formData.append('kyc', this.state.selectedPassport);
    } else {
      formData.append('kyc', this.state.selectedFace);
    }
    this.uploadFile(formData, index);
  }

  uploadFile = (file, index) => {
    axios.post(API + `/api/v1/upload-file`, file, {
      onUploadProgress: (ProgressEvent) => {
        if (index == 0) {
          this.setState({
            p_loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100
         });
        } else {
          this.setState({
            f_loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100
         });
        }
      }
    })
    .then((response) => {
      if (response.data.status == "uploaded") {
        socket.emit(C.UPDATE_KYC_DOCS, encode({ index: index, path: response.data.saveAs }));
        socket.on(C.UPDATE_KYC_DOCS, (data) => {
          let decoded = decode(data);
          socket.off(C.UPDATE_KYC_DOCS);
          if (decoded.success) {
            if (index == 0) {
              this.setState({
                p_loaded: null,
                p_uploaded: true,
             });
            } else {
              this.setState({
                f_loaded: null,
                f_uploaded: true,
             });
            }
          } else {
            sendNotfication(this.props.t('some_issue_on_our_server'), "info", "top-center");
          }
        });
      }
    })
    .catch((err) => {
      console.log("error")
    })
  }

  a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  handleChange(event, newValue) {
    this.setState({value: newValue});
  }

  render() {
    let UNIT = this.state.coin;
    let {
      loader,
      error,
      showArchive,
      amount,
      credit,
      error2,
      minFee,
      fee_withdrawal,
      error3,
      error_minimum,
      affilate,
      credits,
      withdrawal_fee_percent,
      withdrawal_min
    } = this.state;
    const { t } = this.props;
    let details = withdrawlDetail(amount, fee_withdrawal, UNIT, credit, error, affilate, t, credits, withdrawal_fee_percent,);
    const top = true;
    return (
      <>
        {showArchive ? (
          <div className={this.state.slide === false ? "animated fadeOut" : ""}>
            <WithdrawlArchive clicked={this.showArchive} t={t} />
          </div>
        ) : (
          <>
             {/*<AppBar position="static" color="transparent">
              <Tabs
                  value={this.state.value}
                  onChange={this.handleChange}
                  indicatorColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs"
                  centered
                  className="tabsdeposit tabwithdrawal"
              >
                  <Tab icon={<i className={'mdi mdi-chart-areaspline deposittab'} />} label={t('withdrawal')} {...this.a11yProps(0)} />
                   <Tab icon={<i className={' mdi mdi-chart-bar deposittab'} />} label={t('kyc_verification')} {...this.a11yProps(1)}/> 
              </Tabs>
            </AppBar>*/}
              <div className="pt-3 withdrawalheader">
                <SwipeableViews
                  axis={'x' === 'rtl' ? 'x-reverse' : 'x'}
                  index={this.state.value}
                  onChangeIndex={this.handleChangeIndex}
                >
                  <TabPanel value={this.state.value} index={0} dir={'x'}>
                    <div className={this.state.slide ? "animated fadeOut mt-1" : " mt-1"}
                    >
                      <>
                        <div className={"m-auto wallet"}>
                          <form
                            className="w-100"
                            onSubmit={(e) => {
                              this.submitForm(e);
                            }}
                          >
                            {loader ? (
                              <></>
                            ) : (
                              <Row className="animated fadeIn">
                                <Col md="8" lg="8" sm="12" className="walletwithdrawal">
                                  <div className="form-group mb-3">
                                    <label htmlFor="targetWallet" className="text-white">
                                      {t('enter_your_wallet_address')}
                                    </label>
                                    <div className="input-group">
                                      <input
                                        type="text"
                                        value={this.state.wallet}
                                        className="form-control bgl2"
                                        name="wallet"
                                        id="targetWallet"
                                        placeholder=""
                                        onChange={this.handleInputChange}
                                        required={true}
                                        autoComplete={"off"}
                                        disabled={!(this.state.isKycVerified == 1)}
                                      />
                                      <span className="input-group-append">
                                        <div className="input-group fw">
                                          <Dropdown
                                            top={top.toString()}
                                            className={
                                              "drop-select clist3 btn-block bgl2 py-0 pt-2"
                                            }
                                          >
                                            <Dropdown.Toggle
                                              split
                                              variant=""
                                              className={"py-0 mt-0 bgl2"}
                                            >
                                              <span className="text-muted text-center font-12">
                                                <span className="caret mr-1"></span>
                                                <img
                                                  src={   
                                                    "/assets/images/" + __.replace(__.toLower(this.state.coin), '_', '-') + ".png"
                                                  }
                                                  className={"mini-coin-9"}
                                                  alt="COIN"
                                                />
                                                {
                                                  // __.replace(this.state.coin, '_', '-')
                                                  __.includes(__.toUpper(this.state.coin), 'USDT_') ? 'USDT' : __.toUpper(this.state.coin)
                                                }
                                              </span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu
                                              className={"dopdown-menu-bottom dropdownwithdrawal"}
                                              align={"left"}
                                              style={{ margin: "10px 0 0 -5px"}}
                                              renderOnMount
                                            >
                                              {this.state.list}
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </Col>
                                <Col md="4" lg="4" sm="12">
                                  <label htmlFor="amount" className="text-white">
                                    {t('enter_amount')} ({__.includes(__.toUpper(UNIT), 'USDT_') ? "USDT" : UNIT})
                                  </label>
                                  <div className="input-group">
                                    <input
                                      type="number"
                                      // value= {this.state.amount}
                                      value={this.state.amount}
                                      className="form-control bgl2"
                                      name="amount"
                                      id="amount"
                                      onChange={this.handleInputChange}
                                      required={true}
                                      autoComplete={"off"}
                                      disabled={!(this.state.isKycVerified == 1)}
                                    />
                                    <div className="input-group-append">
                                      <Button
                                        variant="bg-greys no-shadow btn-sm btn-clipboard withdrawalclipboard"
                                        onClick={this.setMaxBits}
                                      >
                                        <i className="mdi mdi-coins mr-1" /> {t('max')}
                                      </Button>
                                    </div>
                                    {error && (
                                      <ul className="m-0 mt-1 d-inline-block w-100 p-0">
                                        <li className="text-error font-12">
                                          {t('balance_not_enough')}
                                        </li>
                                      </ul>
                                    )}
                                    {error3 && (
                                      <ul className="m-0 mt-1 d-inline-block w-100 p-0">
                                        <li className="text-error font-12">
                                          {error3}
                                        </li>
                                      </ul>
                                    )}
                                    <ul className="m-0 mb-4 mt-2 d-inline-block w-100 p-0">
                                      <li className={`${error_minimum ? 'text-error' : 'text-yellow'} font-12`}>
                                        {/* {t('minimum')} : {removeTrailingZeros(usdToCrypto(withdrawal_min, this.state.coin))} {UNIT} */}
                                        {t('minimum')} : {withdrawal_min} {UNIT}
                                      </li>
                                    </ul>
                                  </div>
                                </Col>
                                <Col sm="12">
                                  <div className="form-group text-center withdrawalbuttondiv">
                                    {
                                      this.state.isKycRequired ? 
                                        <button
                                          onClick={() => {this.setState({value: 1})}}
                                          className="btn btn-s-4 btn-sm px-4 kycrequirebutton"
                                          type="submit"
                                        >

                                        <svg fill="#ffffff" width="22px" height="22px" viewBox="-6 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
                                          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                          <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                          <g id="SVGRepo_iconCarrier"> 
                                            <title>flag</title> 
                                            <path d="M3.375 26.625v-17.656c0.719-0.438 1.094-1.125 1.094-1.938 0-1.219-1-2.25-2.219-2.25-1.25 0-2.25 1.031-2.25 2.25 0 0.813 0.438 1.5 1.156 1.938v17.656c0 0.313 0.219 0.563 0.5 0.563h1.156c0.313 0 0.563-0.25 0.563-0.563zM4.469 9.969v10.125c0 0.594 0.313 0.844 0.688 0.563 0.719-0.531 1.344-0.938 1.906-1.219 1.156-0.531 1.969-0.781 2.719-0.813 0.938 0 1.656 0.188 2.375 0.531 0.688 0.313 1.313 0.813 2.031 1.281 0.406 0.25 0.906 0.406 1.469 0.438 0.938 0.063 2.25-0.156 3.781-1.406 0.344-0.281 0.625-1 0.625-1.531v-10.125c0-0.594-0.25-0.813-0.625-0.531-0.781 0.594-1.5 1-2.125 1.188-1.281 0.344-2.281 0.281-3.125-0.219-0.719-0.469-1.344-0.969-2.031-1.281-0.719-0.344-1.438-0.531-2.375-0.531-0.344 0-0.781 0.094-1.25 0.219-0.844 0.25-1.938 0.781-3.375 1.781-0.375 0.281-0.688 0.969-0.688 1.531z"></path> 
                                          </g>
                                          </svg>
                                        {t('kyc_is_required')}
                                        </button>
                                      :
                                        <>
                                          <button
                                            onClick={e => this.submitForm(e)}
                                            className="btn btn-s-4 btn-sm px-4 withdrawalbutton"
                                            type="submit"
                                            disabled={this.state.loading || !(this.state.isKycVerified == 1) || !this.state.isRequestAvailable}
                                          >
                                            <i className="dripicons-wallet coinwalleticon"></i>
                                            {t('request_withdrawl')}
                                          </button>
                                          { !this.state.isRequestAvailable && <div className="help-block">You did not met Wagering Policy of 2x deposit. Please check ToS.</div>}
                                        </>
                                    }
                                    {
                                      this.state.isKycVerified == 0 &&
                                      <span className="verification-hint">*{t('need_completed_kyc_verification')}</span>
                                    }
                                    {
                                      this.state.isKycVerified == 2 &&
                                      <span className="verification-hint">{t('kyc_not_approved_need_upload_again')}</span>
                                    }
                                  </div>
                                </Col>
                              </Row>
                            )}
                          </form>
                          <div className="form-group mt-2 mb-0 infowithdrawaltop">
                            {loader ? (
                              <div className="ycenter text-center"></div>
                            ) : (
                              <div className="withdrawl-detail p-1 bg-soft-dark text-white rounded-div">
                                {details}
                              </div>
                            )}
                            {(loader || isMobile()) ? (
                              <></>
                            ) : (
                              <p className="mt-1 mb-0 p-2 bg-soft-dark text-white infowithdrawal">
                                {t('withdraw_modal_t_1')}                          
                              </p>
                            )}
                            <span className="encryption encryption2"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="lock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-lock fa-w-14"><path fill="currentColor" d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"></path></svg>256-bit {t('encryption')}</span>
                          </div>
                        </div>
                      </>
                    </div>
                  </TabPanel>
                  <TabPanel value={this.state.value} index={1} dir={'x'}>
                    <Row className="d-flex align-items-center justify-content-between">
                      <Col md="5" lg="5" sm="5">
                        <Row>
                          <div className="f-w-bold">
                            ID / {t('passport')}:
                          </div>
                          <div className="f-w-10-bold">
                            {t('upload_clear_image_for_id')}
                          </div>
                        </Row>
                      </Col>
                      <Col md="7" lg="7" sm="7">
                        <input type="file" id="passportFile" onClick={e => e.stopPropagation()} onChange={this.onPassportFileChange} style={{display: 'none'}} accept=".jpg, .jpeg, .png"/>
                        {
                          this.state.selectedPassport ?
                          <div className={this.state.p_uploaded? "uploaded-image uploaded-image-center":"uploaded-image"}>
                            <img src={this.state.selectedPassport.src}/>
                            {
                            this.state.p_uploaded?
                            <Stack className="uploaded-badge" direction="horizontal" gap={2}>
                              <Badge bg="success">{t('uploaded')}</Badge>
                            </Stack>
                            :
                            <div className="upload-btns">
                              <Button variant="success" disabled={this.state.p_loaded} onClick={() => this.upload(0)}>
                                {
                                  this.state.p_loaded?
                                    this.state.p_loaded != 100?
                                    <span className="upload-percent">{this.state.p_loaded.toFixed(2)} %</span>
                                    :
                                    <div className="spinner-border spinner-slots-real spinner-border-sm text-white mr-1" role="status" />
                                  :
                                  <>{t('upload')}</>
                                }
                              </Button>
                              <Button variant="warning" disabled={this.state.p_loaded} onClick={() => {
                                this.setState({selectedPassport: null})
                              }}>{t('remove')}</Button>
                            </div>
                            }
                          </div>
                          :
                          <div className="upload-image" onClick={() => {
                            document.getElementById('passportFile').click();
                          }}>
                            <img src="/assets/images/upload-to-the-cloud-interface-symbol.svg" alt="Upload image" />
                          </div>
                        }
                      </Col>
                    </Row>

                    <Row className="d-flex align-items-center justify-content-between pt-3">
                      <Col md="5" lg="5" sm="5">
                        <Row>
                          <div className="f-w-bold">
                            {t('id_and_face')}:
                          </div>
                          <div className="f-w-10-bold">
                            {t('upload_clear_image_for_passport_id')}
                          </div>
                        </Row>
                      </Col>
                      <Col md="7" lg="7" sm="7">
                        <input type="file" id="faceFile" onClick={e => e.stopPropagation()} onChange={this.onFaceFileChange} style={{display: 'none'}} accept=".jpg, .jpeg, .png"/>
                        {
                          this.state.selectedFace ?
                          <div className={this.state.f_uploaded? "uploaded-image uploaded-image-center":"uploaded-image"}>
                            <img src={this.state.selectedFace.src}/>
                            {
                            this.state.f_uploaded?
                            <Stack className="uploaded-badge" direction="horizontal" gap={2}>
                              <Badge bg="success">{t('uploaded')}</Badge>
                            </Stack>
                            :
                            <div className="upload-btns">
                              <Button variant="success" disabled={this.state.f_loaded} onClick={() => this.upload(1)}>
                                {
                                  this.state.f_loaded?
                                    this.state.f_loaded != 100?
                                    <span className="upload-percent">{this.state.f_loaded.toFixed(2)} %</span>
                                    :
                                    <div className="spinner-border spinner-slots-real spinner-border-sm text-white mr-1" role="status" />
                                  :
                                  <>{t('upload')}</>
                                }
                              </Button>
                              <Button variant="warning" disabled={this.state.f_loaded} onClick={() => {
                                this.setState({selectedFace: null})
                              }}>{t('remove')}</Button>
                            </div>
                            }
                          </div>
                          :
                          <div className="upload-image" onClick={() => {
                            document.getElementById('faceFile').click();
                          }}>
                            <img src="/assets/images/upload-to-the-cloud-interface-symbol.svg" alt="Upload image" />
                          </div>
                        }
                      </Col>
                    </Row>
                    <div className="f-w-12-bold mb-0 p-2 bg-soft-dark text-white infowithdrawal">{t('withdraw_modal_note')}</div>
                    <span className="encryption encryption2"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="lock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-lock fa-w-14"><path fill="currentColor" d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"></path></svg>256-bit {t('encryption')}</span>
                  </TabPanel>
                </SwipeableViews>
              </div>
          </>
        )}
      </>
    );
  }
}

function withdrawlDetail(amount, fee, coin, credit, error, affilate, t, credits, withdrawal_fee_percent) {
  // let total = forceSatoshiFormat(Number(credits[__.toLower(coin)]) - Number(fee));
  let total_balance = credits[__.toLower(coin)] ? forceSatoshiFormat(credits[__.toLower(coin)]) : 0;
  return (
    <>
      <ul className={"p-2 m-0 withdraw-detail-container"}>
        <li>
          {/* {t('wallet_balance')}: <span className="centercoin">{forceSatoshiFormat(amount)} {coin}</span> */}
          {t('wallet_balance')}: <span className="centercoin">{removeTrailingZeros(total_balance)} {coin}</span>

        </li>
        {/* <li className="withdraw-detail-wrapper"><span className="withdraw-title">{t('withdrawal_balance')} (-10% {t('provider_fee')}): </span><span className="text-yellow centercoin withdraw-value"><b>{forceSatoshiFormat(credits[__.toLower(coin)] * 0.9)} {coin}</b></span></li> */}
        <li className="withdraw-detail-wrapper"><span className="withdraw-title">{t('withdrawal_balance')} (-{withdrawal_fee_percent}% {t('provider_fee')}): </span><span className="text-yellow centercoin withdraw-value"><b>{removeTrailingZeros(forceSatoshiFormat(total_balance * (100 - withdrawal_fee_percent) / 100))} {coin}</b></span></li>
        <li className="withdraw-detail-wrapper withdraw-affilate"><span className="withdraw-title">{t('affiliate_revenue')} ({t('in')} USDT): </span><span className="text-yellow centercoin withdraw-value"><b>{affilate ? forceSatoshiFormat(affilate, 'USDT', true) : "0 USD"}</b></span></li>
      </ul>
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`full-width-tabpanel-${index}`}
          aria-labelledby={`full-width-tab-${index}`}
          {...other}
      >
          {value === index && (
              <Box p={0}>
                  <Typography component={'span'}>{children}</Typography>
              </Box>
          )}
      </div>
  );
}

export default Withdrawl;