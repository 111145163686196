/**
 * Socket Constant
 */

const values = {
    CREDIT: '660cb6fe7737d7b70e7a07b706b93f70',
    MY_BETS: 'fd2a0537bcdae1736f552707b3bd3156',
    MY_HISTORY: 'fd2a0537bcdae1736f552707b3bd3157',
    GET_BETS_COUNT: 'fd2a0537bcdae1736f552707b3bd3158',
    BANKROLL: '0c30c5a602062107a5d356d0eb1ebb8e',
    LAST_BETS_BY_GAME: 'b87a2e8036f0617125ffb69dd5673d7b',
    LAST_BETS: '62f8c260fbce6de8e5ed19767977cc1e',
    LAST_WITHDRAWALS: '62f8c260fbce6de8e5ed19767977cc20',
    TOP_WINNERS: 'b7cafd57089c07ade71b7776085660a0',
    GET_ADDRESS: '396bbdcf7c16c3f3795d932b698ef78f',
    CREDIT_COIN: 'e70b7663b91b67a7f7e027c00f5a30e2',
    SELECTED_COIN: 'e70b7663b91b67a7f7e027c00f5a30e3',
    USER_INFO: '18566cda79f670c2098360799275aa31',
    USER_CHART: '1cf37d076d187195c2d7d5e3678dfe0b',
    GAME_DETAILS: '657cdcaf1b9072c7d708bb3766bd3915',
    NOTIFICATION: 'f37bd2f66651e7d76f6d38770f2bc5dd',
    UPDATE_PAYMENT_STATUS: '23723ece8c3db7267fcaa661ec6a7f72',
    DEPOSIT_HISTORY: 'cccff8ec16dfd682555f7ef1566c7757',
    CHAT_ABLE: 'cccff8ec16dfd682555f7ef1566c7758',
    MESSAGES: 'de70938879b75d3db63bba721c93e018',
    ADD_MESSAGES: '292d72d37f7e189059f7f998737de9bb',
    SUBMIT_NEW_WITHDRAWL: '7c0b37955cf21c7f2f3773c1268edc08',
    ADD_CHAT: '1e6ccf0ddced017179b173e5cc78beea',
    CHATS: '7a7fe97bbc5ff21a561b79986db975c5',
    ADD_FRIEND: '265ea6ce905188a0751e8f0273d30bb7',
    SEND_TIP: '573a867973fa586555cab080e7d837ad',
    EDIT_ACCOUNT: 'ca6e08ddde39ee9f965270b7d8175d17',
    EDIT_PASSWORD: 'ed7feda03376fd39087183552f093e6a',
    WALLET_HISTORY: 'c23c59dd3258d3a53d7132652f8bf98a',
    MY_FRIENDS: '1e73d7d857e371f00a56105a7a38a576',
    SAVE_ACCOUNT: '70a765ca577e8cc77d3e27f70e56b237',
    SAVE_ACCOUNT_PASSWORD: 'bd3d5bf93eb508dc9282a1077a16a773',
    SAVE_AVATAR: 'd0779dab750dc765ddcf06b190ad82bf',
    ONLINE: '7f76165777d11ee5836777d85df2cdab',
    GET_UID: '002b67aa7d872615cc6ef9ffa78c766d',
    AUTH: 'fa53b91ccc1b78668d5af58e1ed3a785',
    UPDATE_CREDIT: '80d8b773e76b21777faaccfbd3c2a687',
    ADD_BET: '86751663a7022702d9630a115515da7b',
    SUPPORT_EMAIL: '92beb160c15977c905cb7b72138e26c0',
    IS_STREAMER: '92beb160324354977cxa5c217b72138e26c0',
    PROMO_USED: '92beb160324354977cxa5c217b72138e26c1',
    LOGIN_USER: 'faf9ba208ad90e7313b6ffafde53b801',
    LOGIN_USER_GOOGLE: '383f7bf0257c3ef6cab20278dd1579be',
    REGISTER_USER: '0a2637735ee07dd5f0e5eba7b9ca1ce7',
    RESET_PASSWORD: '62a0b91a9b98a7ec19f27e72c13de207',
    UPDATE_PASSWORD: "62a0b91a9b98a7ec19f27e72c13de208",
    LOGOUT_USER: '1f7009c5312bab76e660578ecbe08350',
    ONLINE_LOGGED: 'faf9ba208bd90e2313b6faeede53b801',
    CREDIT_ERROR: '1fdf15e7dcba3211ebe22e5fdbcec79f',
    RAIN: '23678db5efde9ab76bce8c23a6d91b50',
    MY_AFF: '158674db5efde9ab76bce8c23a6d91b50',
    GET_AFFILATE: '158674db5efde9ab76bce8c23a6d91b51',
    GET_REFERRED_USER: "158674db5efde9ab76bce8c23a6d92b51",
    AFF_REGISTER: '158674db5efde9ab76bce8c23a6d91b52',
    DISCONNECT: 'disconnect',
    TWO_FA: '158231da5231e9ab76b2323232136d91b50',
    TWO_FA_CONFIRM: '158231da52345s194323232211136d91b50',
    TWO_FA_DISABLE: '1582223323345s19432325311136d91b50',
    SEND_TIP_SELF: '1c21f730837b25132153ed7063e7726D',
    REGISTER_TIME_DIFFER: '1c21f730837b25132153ed7063e7726E',
    WAGERED_AMOUNT: '1c21f730837b25132153ed7063e77270',
    WAGERED_AMOUNT_FOR_CLAIM: "2c21f730837b25132153ed7063e77270",
    MUTE_USER: '1c21f730837b25132153ed7063e77271',
    REMOVE_MESSAGE: '1c21f730837b25132153ed7063e77272',
    GET_VIP_LEVEL: '1c21f730837b25132153ed7063e77273',
    GET_VIP_LEVELS: '1c21f730837b25132153ed7063e77274',
    GET_SETTING: '1c21f732112b25132143ee7063e77273',
    TOKENS_PRICE: '1c21f730837c25132153ed7263e73270,',
    USER_STATS: "1c21f730837c3532a24637263e73270,",
    LEADERBOARD: "1c21f7e04137c3532a3217263e73278,",
    ONLINE_USERS: "52ed80ea4875ce5a85212a01a960ff92",

    //Sprot Bets
    SPORT_GAME: '1c21f3308372251321e3ed7063e7726D',
    
    FUN_PLAY: "f464cc8e884061ec09213186bdb2d9c4",

    TOGGLE_HIDE_STATS: "15e92a8d237dd250a331d1f33967175a",

    //Admin
    ADMIN_SET_MUTE: 'eca6e08ddde39e22f965270b7d8175d17',
    ADMIN_ADD_AVATAR: '15e76a8d237dd050a301d1f33967175a',
    ADMIN_ADD_CHAT: '2118e57f1f2bb7979c9a7796d6be671d',
    ADMIN_ADD_BOT: '2638817f1f2bbs979c9a779626be671d',

    //Slots
    SLOTS: '15867442221de21b76bce82123a6d91120',
    PLAT_SLOTS: '1c21f730837b2f96d129877063d7726E',
    ADD_SLOT_BET: '1c21f730837b2f96d129877063e7726D',

    //Games
    GAMES: 'f464cc8e884061eb09553186bdb2e9c1',
    FAVOURITES: 'f464cc8e884061eb09553186bdb2e9c2',
    UPDATEFAVOURITES: 'f464cc8e884061eb09553186bdb2e9c3',
    RECENT: 'f464cc8e884061eb09553186bdb2e9c4',
    UPDATERECENT: 'f464cc8e884061eb09553186bdb2e9c5',
    PLAYING_STATUS: 'c464cc8e8842a21b09553186cdb2e9c5',

    STATUS_CLASSIC_DICE: '87d6e86fe91be15e57e126a951beae08',
    PLAY_CLASSIC_DICE: '05db5c137ef2e883b7087edce72e2560',
    BUSTED_CLASSIC_DICE: 'f9755211defdeb7e7e12ed365bd35b79',
    ERROR_CLASSIC_DICE: 'ef39faa35d2060a86223756ad06a18a2',

    STATUS_HASH_DICE: 'd7f1c3cb9bfe76823a51a78637a7faa5',
    PLAY_HASH_DICE: '893295c0a9bc0fe35edf976858c08ba9',
    BUSTED_HASH_DICE: 'ab8e798a36787206eb7dd6f190261caf',
    ERROR_HASH_DICE: 'ef39faa35d2060a86223756ad06a18a2',

    STATUS_MINE: '918b81db5e91d031578b963c93875e5b',
    PLAY_MINE: 'efc657038309b57bd7ce999191a10f51',
    CLICKED_MINE: '3d7d71f8b39b977f17ba6f070bb7a8d1',
    CASHOUT_MINE: 'bf08d86b806e89d7627d89109ed33677',
    BUSTED_MINE: '9d72598debd7b3be7937b113396370f9',
    ERORR_MINE: '952f978bd7b3d892a2f3f1c3f75dcc99',
    CHECK_MINE: '852f978bd7b3d14783f1c3f75dcc99',

    CHECK_HILO: '3d9dd08112ab25f3b57813790988f662',
    STATUS_HILO: '6e9dd081f0ab25f3b57813790988f662',
    PLAY_HILO: '9ce3bafdf91d8deaae771e67bb2b3eea',
    BUSTED_HILO: '3ddbdbcc255f3136957781c2285d8277',
    CASHOUT_HILO: '37183e55acd9736705cf67ac7bfebd00',
    ERROR_HILO: 'fd5ecee21ceb5731285a32711eee706b',

    STATUS_CRASH: 'dcaa9fd7f23aaf0c29f570becf35b76f',
    PLAYERS_CRASH: '0fd0a8ecb587292055e1c775d6c39a7e',
    PLAY_CRASH: '05131bff83db9a797b5e9793cfa3bcf6',
    FINISH_CRASH: '97c73db9a306213ac2b5c3bdecd20e75',
    WAITING_CRASH: 'be91b2a797f2961c59b2780d2cd72e12',
    STARTED_CRASH: 'e112267b9590259c29b9301fa39c1f3d',
    BUSTED_CRASH: 'a8f6d02877d198b08b7c7f2a1af69d06',
    ERROR_CRASH: 'd9fe15b677f93abbe07076807291e9c6',
    HISTORY_CRASH: 'd9fe15b677f93abce07076807291e2d6',

    STATUS_WHEEL: '5eda0ea98768e91b815fa6667e7f0178',
    PLAY_WHEEL: 'c8286908aae1ad02a33b83dd9f827921',
    BUSTED_WHEEL: '7f99f77160fcd2db71126301a960ff77',
    ERROR_WHEEL: '6ea7f872b857889c21f8c5dd2833b8b2',

    STATUS_KENO: 'd57cd08cb7980bfea9552583d35bbcb6',
    PLAY_KENO: 'a68791c6937532f98fa1be087171f1cc',
    WAITING_KENO: '66758925029b8e0c7e327cabc7b77139',
    STARTED_KENO: 'e152b89f879258252dbbbab7799c1f9c',
    BUSTED_KENO: '6392cf7c311ae3ec7daec13d886c8755',
    KENO_AMOUNT: '312c65a895c7366ce3afe2eefea00c07',
    ERORR_KENO: '555020f1aa7a306a5ef93c9c57c7bfa1',

    STATUS_SINGLE_KENO: '70971a2722e280c337d30e93c37f8a66',
    PLAY_SINGLE_KENO: '5a828e282af3d79f90ad3b7763052d6e',
    BUSTED_SINGLE_KENO: '1fcc8361a035dbe7a63f290ada5778fd',
    ERROR_SINGLE_KENO: '2663d265faad0226a1db572228e108c9',

    STATUS_PLINKO: '8352353a2cdc5271f9ec72f7d83395fd',
    PLAY_PLINKO: 'f31c1e97179a0c766a9da0fdde28d3ed',
    BUSTED_PLINKO: '08fb63257693a2d07a6f32e77310695e',
    ERROR_PLINKO: '73977220535be6896993770579307689',

    STATUS_LIMBO: 'f715ed50ac09682c7fceec6b397b78d5',
    PLAY_LIMBO: '18867ffabc768e07378cdaa6df18c75c',
    BUSTED_LIMBO: 'b006d95a97feb32faf257228d6030bfb',
    ERROR_LIMBO: '7c385c590a77652a67508ebc2e63b0f6',

    PLAY_ROULETTE: '1c21f730837b2f96d129877063d7720B',
    BUSTED_ROULETTE: '1c21f730837b2f96d129877063d7720W',
    ERROR_ROULETTE: '1c21f730837b2f96d129877063d7720C',

    CHECK_VIDEOPOKER: '2c21f730837b2e96d119877063d2720D',
    PLAY_VIDEOPOKER: '1c21f730837b2f96d129877063d7725Q',
    BUSTED_VIDEOPOKER: '1c21f730837b2f96d129877063d7731W',
    ERROR_VIDEOPOKER: '1c21f730837b2f96d179877063d2720D',
    DEAL_VIDEOPOKER: '1c21f73083711f96d179877023d2720D',

    CHECK_BLACKJACK: '4e21e830833d2f96d129877063d7725Q',
    PLAY_BLACKJACK: '1c21e830837b2f96d129877063d7725Q',
    BUSTED_BLACKJACK: '1c31f730837b2f96d129827063d7731W',
    ERROR_BLACKJACK: '1c31f730837b2f92d179877063d2720D',

    CHECK_GOAL: "112b81db5192a031578b9c3293875e5b",
    STATUS_GOAL: '912b81db5191d031578b963c93875e5b',
    PLAY_GOAL: 'e2c657038309b57bd7ce999191a10f51',
    CLICKED_GOAL: '3d7d71f8b392977f17ba6f070bb7a8d1',
    CASHOUT_GOAL: 'bf03d86b806e89d7627d89109ed33677',
    BUSTED_GOAL: '9d72598debd7b3be7937b111296370f9',
    ERORR_GOAL: '952f978bd7b3d892a3213f1c3f75dcc99',

    CHECK_SNAKEANDLADDERS: "e2c6570213217bd7cede291a10f51",
    PLAY_SNAKEANDLADDERS: 'e2c657021309b57bd7ce999191a10f51',
    NEXT_SNAKEANDLADDERS: 'e2c65704231b57be851e999191a10f51',
    CLICKED_SNAKEANDLADDERS: '3d7d71e1b392977f17ba6f070bb7a8d1',
    CASHOUT_SNAKEANDLADDERS: 'bf03d212806e89d7627d89109ed33677',
    BUSTED_SNAKEANDLADDERS: '9d72598de4327b3be7937b111296370f9',
    ERORR_SNAKEANDLADDERS: '952f978bd7b3d534a3213f1c3f75dcc99',

    PLAY_BACCARAT: '1c21e830837b2f9612121133sd7725Q',
    BUSTED_BACCARAT: '1c31f12135b2f96d129827063d7731W',
    ERROR_BACCARAT: '1c31f730837b2f92d223232d2720D',

    CHECK_THREE_CARD_MONTE: '3c21e82c212f96113sd7725Q',
    PLAY_THREE_CARD_MONTE: '1c21e823212f96113sd7725Q',
    BUSTED_THREE_CARD_MONTE: '1c31f1213521236d129827063d7731W',
    ERROR_THREE_CARD_MONTE: '1c3273083723133232d2720D',

    PLAY_JOCKER: '1c2233e3212f96113sd7725Q',
    BUSTED_JOCKER: '1c31f1213521231129827063a731W',
    ERROR_JOCKER: '1c32730832133232d2720D',

    PLAY_MAGIC_WHEEL: '5eda0ea98768e9123231667e7f0178',
    BUSTED_MAGIC_WHEEL: '7f99f72323fcd2db71126301a960ff77',
    ERROR_MAGIC_WHEEL: '6ea7f872232357889c21f8c5dd2833b8b2',

    PLAY_TOWER: '5eda0ea98752c5a85223231667e7f0178',
    BUSTED_TOWER: '7f99f783123fcd2db71126301a960ff77',
    ERROR_TOWER: '6ea7f8722323343441c21f8c5dd2833b8b2',
    CASHOUT_TOWER: '6ea7f8722323232325241c21f8c5dd2833b8b2',

    PLAY_DIAMOND: "5eda0ea98752c5a85212a01a9121260ff77",
    BUSTED_DIAMOND: "7f9asdasdas2741231421453301a960ff77",
    ERROR_DIAMOND: "6ea7f8721212342348c5dd2833b8b2",

    CRYPTO_TRADING: '5eda0ea98732c5a852121a9603977',
    PLAY_CRYPTO_TRADING: '5eda0ea98752c5a85212a01a960ff77',
    CASHOUT_CRYPTO_TRADING: '7f9f7831223231231421453301a960ff77',
    ERROR_CRYPTO_TRADING: '6ea7f8722323523423121d2833b8b2',
    RATES_CRYPTO_TRADING: '6ea7e2722323142348cd212833b8b2',
    CHECK_CRYPTO_TRADING: '3ea7e292232e1cc348cd212832b8b3',

    //Slotegrator
    GET_INITIAL_ALL_GAMES: '5eda0ea98752c5a85212a01a960ff78',
    GET_SEARCHED_SLOTS: '6eda0ea98752c5a85212a01a960ff79',
    GET_LOADER_SLOTS: '5eda0ea98752c5a85212a01a960ff80',
    GET_POPULAR_SLOTS: '6eda0ea98752c5a85212a01a960ff80',
    ADD_POPULAR_SLOTS: '6eda0ea98752c5a85212a01a960ff81',
    GET_TOP_SLOTS: '6eda0ea98752c5a85212a01a960ff82',
    ADD_TOP_SLOTS: '6eda0ea98752c5a85212a01a960ff83',
    GET_FEATURED_SLOTS: '6eda0ea98752c5a85212a01a960ff84',
    ADD_FEATURED_SLOTS: '6eda0ea98752c5a85212a01a960ff85',
    GET_DEMO_GAME: '5eda0ea98752c5a85212a01a960ff79',
    GET_REAL_GAME: '5eda0ea98752c5a85212a01a960ff82',
    GET_SLOT_BY_ID: '5eda0ea98752c5a85212a01a960ff81',
    GET_USER_PROFIT: '5eda0ea98752c5a85212a01a960ff83',
    UPDATE_USER_PROFIT: '5eda0ea98752c5a85212a01a960ff84',
    IS_REQUEST_AVAILABLE: '5fda0ea98752c5a85212a01a960ff84',
    ADD_PRAGMATIC_SLOTS: "5eda0ea98752c5b85212a01a960ff85",
    
    //Buy with coinbase
    BUY_COINBASE: "5eda1ea98752c5a85212a01a960ff85",
    //Buy with credit card
    BUY_CREDIT: '5eda0ea98752c5a85212a01a960ff85',
    CREATE_PAYMENT: '5eda0ea98752c5a85212a01a960ff86',
    NOTIFY_PAYMENT: '5eda0ea98752c5a85212a01a960ff87',
    NOTIFY_PAYMENT_CRYPTO: "5eda0ea98752c5a85212a01a960ffa9",
    ACTIVE_PAYMENT: '5eda0ea98752c5a85212a01a960f000',
    // Freespin
    IS_GET_SPIN: '5eda0ea98752c5a85212a01a960ff88',
    GET_ABLE_TIMES: '5eda0ea98752c5a85212a01a960ff95',
    UPDATE_SPIN: '5eda0ea98752c5a85212a01a960ff89',

    // Promotion code
    UPDATE_PROMO: '5eda0ea98752c5a85212a01a960ff90',

    // Lootably
    LOOTABLY_POSTBACK: "5eda0ea98752c5a85212a01a961ff99",

    // Torox
    TOROX_POSTBACK: "5eda0ea98752c5a85212a01a961ffA0",
        
    // ADGATEMEDIA
    ADGATEMEDIA_POSTBACK: "5eda0ece28752c5321a01a9eafA0",

    // Settings
    GET_DEPOSIT_SETTING: '5eda0ea98752c5a85212a01a960ff91',

    // Getting house edge
    GET_HOUSEEDGE: '5eda0ea98752c5a85212a01a960ff92',

    // Report bug
    REPORT_BUG: '5eda0ea98752c5a85212a01a960ff93',

    // GET BLOGS
    GET_BLOGS: '5eda0ea98752c5a85212a01a960ff94',
    // EMAIL Verification
    SEND_EMAIL_VERIFICATION: '5eda0ea98752c5a85212a01a960ff96',
    VERIFY_EMAIL_TOKEN: '5eda0ea98752c5a85212a01a960ff97',
    EMAIL_BONUS: '7f9f783123fcd2db741231421453301a960ff85',

    // Rakeback
    GET_USER_RAKEBACK: '5eda0ea98752c5a85212a01a960ff98',
    
    // Sportsbooks
    GET_ABLE_SPORTSBOOKS: '5eda0ea98752c5a85212a01a960ff99',
    GET_REAL_SPORT: '7f9f783123fcd2db741231421453301a960ff78',

    //GET PROIVDER LIST
    GET_PROVIDER_TYPE_LIST: '5eda0ea98752c5a85212a01a9610001',

    //GET SORTED SLOTS
    GET_SORTED_SLOTS: '5eda0ea98752c5a85212a01a9610002',

    // KYC verification
    GET_KYC_REQUIRED: '7f9f783123fcd2db741231421453301a960ff87',
    GET_KYC_ABLE: '7f9f783123fcd2db741231421453301a960ff79',
    UPDATE_KYC_DOCS: '7f9f783123fcd2db741231421453301a960ff80',
    
    // Get 7 Random Slot Games
    RANDOM_SLOT_GAME: '7f9f783123fcd2db741231421453301a960ff81',

    // GET CURRENT ACTIVE DAY
    LOGIN_BONUS: '7f9f783123fcd2db741231421453301a960ff82',

    // SEND BONUS CLAIM EVENT
    BONUS_CLAIM: '7f9f783123fcd2db741231421453301a960ff83',
    BONUS_TO_PROFIT: '7f9f783123fcd2db741231421453301a961ff85',
    // GET LUCKYSPIN MIN AND MAX VALUE
    LUCKYSPIN_MIN_MAX: '7f9f783123fcd2db741231421453301a960ff84',
    //GET WEEKLY GIVEAWAY 
    GET_WEEKLY_DEPOSITE: '7f9f783123fcd2db741231421453301a960ff86',
    GET_LAST_WEEK_WINNER: '7f9f783123fcd2db741231421453301a960ff88',
    //GET CONTEST INFO
    GET_CONTEST: '7f9f783123fcd2db741231421453301a960ff89',
    START_CONTEST: '7f9f783123fcd2db741231421453301a960ff90',

    GET_ACTIVE_HOUSE: '7f9f783123fcd2db741231421453301a960ff91',

    INTERCOM_APP_ID: 'iiz8i9q5',

    GET_BLOGS_QUIZ: '7f9f783123fcd2db741231421453301a960ff92',
    GET_ONE_BLOGS_QUIZ: '7f9f783123fcd2db741231421453301a960ff93',

    GET_LNG_DETECTION_SETTING: '7f9f783123fcd2db741231421453301a960ff94'

};

export default values;
