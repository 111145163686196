import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import { __, isMobile, wait, Event, sendNotfication, SITE_URL, decode } from "../../../../Helper";
import socket from "../../../../Socket";
import C from "../../../../Constant";
import Translation from "../Translation";
import FreeCredit from "../../../Components/User/FreeCredit";
import isLogged from "../../../../Auth";

class MobileMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            games: [],
            menus: [
                {
                    title: this.props.t('Affilate'),
                    url: '/affiliate',
                    icon: 'mdi-account-multiple-plus',
                },
                {
                    title: this.props.t('Promotion'),
                    url: '/promotion',
                    icon: 'mdi-trophy',
                },
                {
                    title: 'Daily Contest',
                    url: '/daily-contest',
                    icon: 'mdi-emoticon-cool',
                },
                {
                    title: 'VIP ' + this.props.t('levels') ,
                    url: '/vip-levels',
                    icon: 'mdi-emoticon-cool',
                }
            ]
        }
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {

        if (this.state.games.length === 0)
            socket.emit(C.GAMES)

        wait(500).then(() => {
            this.handleResize();
        });
        window.addEventListener('resize', this.handleResize);

        socket.on(C.GAMES, (data) => {
            this.setState({ games: decode(data), loading: false });
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        socket.off(C.GAMES);
    }

    handleResize() {
        if (isMobile()) {
            this.setState({ show: true });
        }
        else {
            this.setState({ show: false });
        }
    }

    render() {
        return (
            <>
                {this.state.show &&
                    <ul className="list-unstyled topbar-nav mb-0 menu-items">
                        <MobileDrawer menus={this.state.menus} games={this.state.games} t={this.props.t} />
                    </ul>
                }
            </>
        );
    }
}

function MobileDrawer(props) {
    const [drawer, setDrawer] = React.useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawer(open);
    };

    const setOfferwal = () => {
        if (isLogged()) {
            Event.emit("offerwall");
            setDrawer(false);
        } else {
            sendNotfication(props.t('you_must_log_in_first'));
        }
    }

    const anchor = 'left';
    const { t, menus } = props;

    const menuLinks = menus.map((menu, i) => (
        <><Link to={menu.url} className='b-subtitle' onClick={() => setDrawer(false)}><i className={'mdi font-15 ' + menu.icon}></i>{menu.title}</Link></>
      ));

    return (
        <>
            <React.Fragment key={anchor}>
                {/* <li className={'nav-link ml-0 mobile-header-menu-btn'} onClick={toggleDrawer(anchor, true)}> */}
                <li className={'nav-link ml-0 mobile-header-menu-btn'} onClick={toggleDrawer(true)}>
                    <svg width="25" height="25" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" >
                        <path d="M0 0h48v48H0z" fill="none"/>
                        <g id="Shopicon">
                            <rect x="8.036" y="12" width="31.964" height="4" style={{fill: "white"}}/>
                            <rect x="8.036" y="22" width="31.964" height="4" style={{fill: "white"}}/>
                            <rect x="8.036" y="32" width="31.964" height="4" style={{fill: "white"}}/>
                        </g>
                    </svg>
                </li>
                <Drawer anchor={anchor} open={drawer} onClose={toggleDrawer(false)} PaperProps={{style: {width: '100%', maxWidth: '380px'}}} className="mobile-menu-drawer">
                    <div className="menu-body m-0">
                        <button className={'btn menu-room-back-btn ms-2'} onClick={() => setDrawer(false)}>
                            <CloseIcon/>
                        </button>
                        <div className="menu-pane d-flex flex-column">

                            <p className='in-house-game-title p-2 m-0'>{t('in_house_game')}</p>
                            <Row xs={4} md={4} className='in-house-game-wrapper w-100 p-2 m-0'>
                                <Col className='col-3 p-1'>
                                    <Link to="/crash" onClick={()=>setDrawer(false)}>
                                        <div className='position-relative'>
                                            <img src="/assets/images/sidebar/in-house/1.svg" className="img-fluid w-100 side-inhome" alt="" />
                                        </div>
                                    </Link>
                                </Col>
                                <Col className='col-3 p-1'>
                                    <Link to="/roulette" onClick={()=>setDrawer(false)}>
                                        <div className='position-relative'>
                                            <img src="/assets/images/sidebar/in-house/2.svg" className="img-fluid w-100  side-inhome" alt="" />
                                        </div>
                                    </Link>
                                </Col>
                                <Col className='col-3 p-1'>
                                    <Link to="/diamond" onClick={()=>setDrawer(false)}>
                                        <div className='position-relative'>
                                            <img src="/assets/images/sidebar/in-house/3.svg" className="img-fluid w-100  side-inhome" alt="" />
                                        </div>
                                    </Link>
                                </Col>
                                <Col className='col-3 p-1'>
                                    <Link to="/plinko" onClick={()=>setDrawer(false)}>
                                        <div className='position-relative'>
                                            <img src="/assets/images/sidebar/in-house/4.svg" className="img-fluid w-100  side-inhome" alt="" />
                                        </div>
                                    </Link>
                                </Col>
                                <Col className='col-3 p-1'>
                                    <Link to="/3-card-monte" onClick={()=>setDrawer(false)}>
                                        <div className='position-relative'>
                                            <img src="/assets/images/sidebar/in-house/5.svg" className="img-fluid w-100  side-inhome" alt="" />
                                        </div>
                                    </Link>
                                </Col>
                                <Col className='col-3 p-1'>
                                    <Link to="/keno" onClick={()=>setDrawer(false)}>
                                        <div className='position-relative'>
                                            <img src="/assets/images/sidebar/in-house/6.svg" className="img-fluid w-100  side-inhome" alt="" />
                                        </div>
                                    </Link>
                                </Col>
                                <Col className='col-3 p-1'>
                                    <Link to="/videopoker" onClick={()=>setDrawer(false)}>
                                        <div className='position-relative'>
                                            <img src="/assets/images/sidebar/in-house/7.svg" className="img-fluid w-100  side-inhome" alt="" />
                                        </div>
                                    </Link>
                                </Col>
                                <Col className='col-3 p-1'>
                                    <Link to="/classic-dice" onClick={()=>setDrawer(false)}>
                                        <div className='position-relative'>
                                            <img src="/assets/images/sidebar/in-house/8.svg" className="img-fluid w-100  side-inhome" alt="" />
                                        </div>
                                    </Link>
                                </Col>
                                
                            </Row>

                            <div className='menu-link-wrapper p-2 d-flex flex-column gap-2'>

                              <Link to={"#"} className="b-subtitle" onClick={() => this.openOffer()}>
                                <button
                                  className={`btn menu-link-btn bonusbtn`}
                                  onClick={() => this.active("")}
                                >
                                  <div className="menu-link-btn-img-wrapper">
                                    <span>GET <span className="hightlight-bonus"> 15 USD</span></span>
                                  </div>
                                </button>
                              </Link>

                                <Link to="/sports">
                                    <button className={`btn`} onClick={() => setDrawer(false)}>
                                        <div className='menu-link-btn-img-wrapper'>
                                            <img className="img-fluid" src="/assets/images/sidebar/recent.svg" />
                                            <span>Sports</span>
                                        </div>
                                    </button>
                                </Link>
                                <Link to={'/live'}>
                                    <button className={`btn menu-link-btn`} onClick={() => setDrawer(false)}>
                                        <div className='menu-link-btn-img-wrapper'>
                                            <img className="img-fluid img-default" src="/assets/images/sidebar/games-info/live_casino.svg" />
                                            <img className="img-fluid img-top" src="/assets/images/sidebar/games-info/live_casino_active.svg" />
                                            <span>{t('live_casino')}</span>
                                        </div>
                                    </button>
                                </Link>
                                <Link to={'/slots'}>
                                    <button className={`btn menu-link-btn`} onClick={() => setDrawer(false)}>
                                    <div className='menu-link-btn-img-wrapper'>
                                        <img className="img-fluid img-default" src="/assets/images/sidebar/games-info/slots.svg" />
                                        <img className="img-fluid img-top" src="/assets/images/sidebar/games-info/slots_active.svg" />
                                        <span>{t('slots')}</span>
                                    </div>
                                    </button>
                                </Link>
                            </div>

                            <div className='menu-text-wrapper p-2 d-flex flex-column gap-2'>

                                <Link to={'#'} className='b-title' onClick={() => setDrawer(false)}>{t('other')}</Link>
                                {menuLinks}

                                <Link to={"#"} className="b-subtitle" onClick={() => setOfferwal()}>
                                    <i class="mdi mdi-coin font-15"></i>Offerwall
                                </Link>
                                <FreeCredit t={t} disableButton={true}/> 
                            </div>

                            <div className='menu-footer-wrapper p-2 d-flex flex-column gap-2'>
                                <div className='d-flex align-items-center'>
                                    <Link to={'#'} className='b-subtitle my_custom_intercom' onClick={() => setDrawer(false)}>Live Support

                                    <svg className="iconchat" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#717A98" d="M18.72 14.76c.35-.85.54-1.76.54-2.76c0-.72-.11-1.41-.3-2.05c-.65.15-1.33.23-2.04.23A9.07 9.07 0 0 1 9.5 6.34a9.21 9.21 0 0 1-4.73 4.88c-.04.25-.04.52-.04.78A7.27 7.27 0 0 0 12 19.27c1.05 0 2.06-.23 2.97-.64c.57 1.09.83 1.63.81 1.63c-1.64.55-2.91.82-3.78.82c-2.42 0-4.73-.95-6.43-2.66a9.03 9.03 0 0 1-2.24-3.69H2v-4.55h1.09a9.09 9.09 0 0 1 15.33-4.6a8.991 8.991 0 0 1 2.47 4.6H22v4.55h-.06L18.38 18l-5.3-.6v-1.67h4.83l.81-.97m-9.45-2.99c.3 0 .59.12.8.34a1.136 1.136 0 0 1 0 1.6c-.21.21-.5.33-.8.33c-.63 0-1.14-.5-1.14-1.13c0-.63.51-1.14 1.14-1.14m5.45 0c.63 0 1.13.51 1.13 1.14c0 .63-.5 1.13-1.13 1.13c-.63 0-1.14-.5-1.14-1.13a1.14 1.14 0 0 1 1.14-1.14Z"/></svg>
                                    </Link>
                                

                                </div>
                                {/*
                                <div className='d-flex align-items-center giveway-link'>
                                    <Link to={'/rules?tab=0'} onClick={() => setDrawer(false)}>Giveaway & Raffle
                                            <div className="giveaway-icon">
                                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 0 426.667 426.667" width="15"><g><g><g><path fill="#717A98" d="M21.333,405.333c0,12.8,8.533,21.333,21.333,21.333H192v-192H21.333V405.333z"/><path fill="#717A98" d="M234.667,426.667H384c12.8,0,21.333-8.533,21.333-21.333V234.667H234.667V426.667z"/><path fill="#717A98" d="M0,128v42.667C0,183.467,8.533,192,21.333,192H192v-85.333H21.333C8.533,106.667,0,115.2,0,128z"/><path fill="#717A98" d="M405.333,106.667H234.667V192h170.667c12.8,0,21.333-8.533,21.333-21.333V128
                    C426.667,115.2,418.133,106.667,405.333,106.667z"/><path fill="#717A98" d="M119.467,85.333l-6.4-6.4c-8.533-8.533-8.533-21.333,0-29.867c4.267-4.267,10.667-6.4,14.933-6.4
                    c6.4,0,10.667,2.133,14.933,6.4L179.2,85.333h6.4h53.333h6.4L281.6,49.067c8.533-8.533,21.333-8.533,29.867,0
                    s8.533,21.333,0,29.867l-4.267,6.4h51.2c8.533-23.467,2.133-49.067-14.933-66.133c-25.6-25.6-66.133-25.6-89.6,0l-40.533,38.4
                    l-38.4-38.4c-25.6-25.6-66.133-25.6-91.733,0C66.133,36.267,59.733,61.867,68.267,85.333H119.467z"/></g></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
                                            </div>
                                    </Link>
                                </div>
                                */}
                                <Link className='termslink' to={'/rules?tab=1'} onClick={() => setDrawer(false)}>{t('terms_conditions')}</Link>
                                <div className="mobilelang">
                                <Translation  mobileDrawer={setDrawer} />
                                </div>
                            </div>

                             <div className="menu-social-wrapper socialiconsmenu p-2 justify-content-start mt-3 d-flex">
              <a href="https://twitter.com/betsmixer" target="_blank">
                <button className={"btn btn-xs menu-social-btn"}>
                  <img
                    className="img-fluid "
                    src="/assets/images/sidebar/social/twitter.svg"
                  />
                </button>
              </a>
              <a href="https://t.me/betsmixer" target="_blank" className="telegramsidebar">
                <button className={"btn btn-xs menu-social-btn"}>
                  <img
                    className="img-fluid "
                    src="/assets/images/sidebar/social/telegram.png"
                  />
                </button>
              </a>
              <Link to={"#"} className="b-subtitle my_custom_intercom">
                <button className={"btn btn-xs menu-social-btn"}>
                  <img
                    className="img-fluid livesupporticon"
                    src="/assets/images/sidebar/social/livesupport.svg"
                  />
                </button>
              </Link>
            </div>
                        </div>
                    </div>
                </Drawer>
            </React.Fragment>
        </>
    );
}

export default MobileMenu;
