import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import storage from "../../../../Storage";
import socket from "../../../../Socket";
import {
  Event,
  decode,
  encode,
  wait,
  sendNotfication,
} from "../../../../Helper";
import C from "../../../../Constant";

class Security extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      disabled: false,
      password: "",
      _password: "",
      twoFa: null,
      disabled2: false,
      disabled3: false,
      activated: false,
      checkbox: false,
      checkbox2: false,
    };
    this.saveUserSetting = this.saveUserSetting.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      socket.on(C.EDIT_PASSWORD, (data) => this.updateProfile(decode(data)));
      socket.on(C.TWO_FA, (data) => this.setupTwoFa(decode(data)));
      socket.on(C.TWO_FA_CONFIRM, (data) => this.confirmTwoFa(decode(data)));
      socket.on(C.TWO_FA_DISABLE, (data) =>
        this.disableTwoFactorStatus(decode(data))
      );
      socket.emit(C.TWO_FA);
    }
  }

  componentWillMount() {
    socket.off(C.EDIT_PASSWORD);
    socket.off(C.TWO_FA);
    socket.off(C.TWO_FA_CONFIRM);
    socket.off(C.TWO_FA_DISABLE);
    wait(500).then(() => {
      this.setState({ loading: false });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setupTwoFa(qr) {
    if (this._isMounted) {
      if (qr === "activated") {
        this.setState({ activated: true });
      } else this.setState({ twoFa: qr });
    }
  }

  confirmTwoFa(data) {
    if (this._isMounted) {
      this.setState({ disabled2: false });

      if (data.status !== true) {
        return sendNotfication(data.status.toString());
      }

      sendNotfication(this.props.t("2fa_successfully_activated"));

      Event.emit("confirm_two_fa");
    }
  }

  saveUserSetting(e) {
    if (this._isMounted) {
      wait(750).then(() => {
        socket.emit(
          C.EDIT_PASSWORD,
          encode({
            oldpassword: this.state.passwordold,
            password: this.state.password,
          })
        );
      });
    }
  }

  confirm = (e) => {
    if (this._isMounted) {
      e.preventDefault();
      if (!this.state.verify_code) return;
      if (!this.state.loginpass) return;
      this.setState({ disabled2: true });
      socket.emit(
        C.TWO_FA_CONFIRM,
        encode({
          code: this.state.verify_code,
          password: this.state.loginpass,
        })
      );
    }
  };

  updateProfile(data) {
    if (this._isMounted) {
      this.setState({ disabled: false });
      if (data.status) {
        return sendNotfication(
          this.props.t("your_account_password_was_updated"),
          "success",
          "top-center"
        );
      } else {
        return sendNotfication(data.error, "error", "top-center");
      }
    }
  }

  disableTwoFactorStatus = (data) => {
    if (this._isMounted) {
      this.setState({ disabled3: false });

      if (data.status !== true) {
        return sendNotfication(data.status.toString());
      }
      sendNotfication(this.props.t("2fa_successfully_disabled"));
    }
  };

  disableTwoFa = (e) => {
    if (this._isMounted) {
      e.preventDefault();
      if (!this.state.verify_code) return;
      if (!this.state.loginpass) return;
      this.setState({ disabled3: true });
      socket.emit(
        C.TWO_FA_DISABLE,
        encode({
          code: this.state.verify_code,
          password: this.state.loginpass,
        })
      );
    }
  };

  handleCheckBox = (e) => {
    this.setState({ checkbox: true, loadingPrivacy: true });
    wait(1200).then(() => {
      this.setState({ loadingPrivacy: false, checkbox: false });
      return sendNotfication(
        this.props.t("this_feature_can_not_active"),
        "info",
        "top-center"
      );
    });
  };

  handleCheckBox2 = (e) => {
    this.setState({ checkbox2: true, loading2: true });
    wait(1200).then(() => {
      this.setState({ checkbox2: false, loading2: false });
      return sendNotfication(
        this.props.t("this_feature_can_not_active"),
        "info",
        "top-center"
      );
    });
  };

  render() {
    const { t } = this.props;
    return (
      <>
        {this.state.loading ? (
          <div className="text-center" style={{ minHeight: 150 }}>
            <div className="spinner-grow my-2" role="status" />
          </div>
        ) : (
          <>
            <Row>
              <Col sm="12" md="6" lg="6" className="border-right-2">
                <h4 className="mb-3">{t("two_factor_authentication")}</h4>
                {!this.state.activated ? (
                  <Form onSubmit={this.confirm}>
                    <Row>
                      <Col sm="12" md="12">
                        <Row className="mt-">
                          <Col sm="12">
                            <div class="input-group mt-">
                              <div class="input-group-prepend">
                                <span class="input-group-text">
                                  <i class="mdi mdi-security"></i>
                                </span>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.verify_code}
                                placeholder={t("verification_code")}
                                onChange={(e) =>
                                  this.setState({ verify_code: e.target.value })
                                }
                              />
                            </div>
                          </Col>
                          <Col sm="12" className="mt-2">
                            <div class="input-group mt-2">
                              <div class="input-group-prepend">
                                <span class="input-group-text">
                                  <i class="mdi mdi-key"></i>
                                </span>
                              </div>
                              <input
                                type="password"
                                className="form-control"
                                value={this.state.loginpass}
                                placeholder={t("login_password")}
                                onChange={(e) =>
                                  this.setState({ loginpass: e.target.value })
                                }
                              />
                            </div>
                          </Col>
                          <Col sm="12" md="12" className="text-center">
                            <button
                              type="submit"
                              className="btn btn-sm my-3 btn-outline-info no-shadow"
                              disabled={this.state.disabled2}
                            >
                              <i className="mdi mdi-content-save-outline align-middle mr-1" />
                              {t("enable")}
                              {this.state.disabled2 && (
                                <>
                                  <div
                                    className={
                                      "ml-2 spinner-border spinner-border-sm"
                                    }
                                  />
                                </>
                              )}
                            </button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                ) : (
                  <>
                    <Form onSubmit={this.disableTwoFa}>
                      <Row>
                        <Col sm="12">
                          <p className="font-14 text-white">
                            {t("security_authentication_t_2")}
                          </p>

                          <div class="input-group mt-2">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <i class="mdi mdi-key"></i>
                              </span>
                            </div>
                            <input
                              type="password"
                              className="form-control"
                              value={this.state.loginpass}
                              placeholder={t("login_password")}
                              onChange={(e) =>
                                this.setState({ loginpass: e.target.value })
                              }
                            />
                          </div>

                          <br />

                          <div class="input-group mt-2">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <i class="mdi mdi-security"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.verify_code}
                              placeholder={t("verification_code")}
                              onChange={(e) =>
                                this.setState({ verify_code: e.target.value })
                              }
                            />
                          </div>

                          <button
                            type="submit"
                            className="btn btn-sm mt-3 btn-outline-info no-shadow"
                            disabled={this.state.disabled3}
                          >
                            <i className="mdi mdi-content-save-outline align-middle mr-1" />
                            {t("desable")}
                            {this.state.disabled3 && (
                              <>
                                <div
                                  className={
                                    "ml-2 spinner-border spinner-border-sm"
                                  }
                                />
                              </>
                            )}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </>
                )}
              </Col>

              <Col sm="12" md="6" lg="6">
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();

                    if (!this.state.password) {
                      document
                        .getElementById("password")
                        .classList.add("border-danger");
                      return;
                    }

                    if (!this.state._password) {
                      document
                        .getElementById("_password")
                        .classList.add("border-danger");
                      return;
                    }

                    if (this.state.password !== this.state._password) {
                      sendNotfication(
                        t("password_is_different"),
                        "error",
                        "top-center"
                      );
                    }

                    if (this.state.password === this.state._password) {
                      this.setState({ disabled: true });
                      this.saveUserSetting(e);
                    }
                  }}
                >
                  <h4 className="mb-3">{t("change_password")}</h4>
                  <Row>
                    <Col sm={12}>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="mdi mdi-key"></i>
                          </span>
                        </div>
                        <input
                          type="password"
                          id="passwordold"
                          autoComplete={"off"}
                          className="form-control"
                          value={this.state.passwordold}
                          placeholder={t("old_password")}
                          required={true}
                          onChange={(e) =>
                            this.setState({ passwordold: e.target.value })
                          }
                        />
                      </div>
                    </Col>
                    <Col sm={12}>
                      <div class="input-group mt-2">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="mdi mdi-key"></i>
                          </span>
                        </div>

                        <input
                          type="password"
                          id="password"
                          autoComplete={"off"}
                          className="form-control"
                          value={this.state.password}
                          placeholder={t("new_password")}
                          required={true}
                          onChange={(e) =>
                            this.setState({ password: e.target.value })
                          }
                        />
                      </div>
                    </Col>
                    <Col sm={12}>
                      <div class="input-group mt-2">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="mdi mdi-key"></i>
                          </span>
                        </div>
                        <input
                          type="password"
                          id="_password"
                          autoComplete={"off"}
                          className="form-control"
                          required={true}
                          value={this.state._password}
                          placeholder={t("confirmation_password")}
                          onChange={(e) =>
                            this.setState({ _password: e.target.value })
                          }
                        />
                      </div>
                    </Col>
                    <Col sm={12} className="text-center mt-2">
                      <button
                        type="submit"
                        className="btn btn-sm btn-outline-info no-shadow"
                        disabled={this.state.disabled}
                      >
                        <i className="mdi mdi-content-save-outline align-middle mr-1" />
                        {t("save_new_password")}
                        {this.state.disabled && (
                          <>
                            <div
                              className={
                                "ml-2 spinner-border spinner-border-sm"
                              }
                            />
                          </>
                        )}
                      </button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>

            <Row>
              <Col sm="12" md="12" className="text- mt-3">
                <Row>
                  <Col md="3" sm="12">
                    <img
                      className="img-fluid rounded border p-1"
                      alt="2FA"
                      src={this.state.twoFa}
                      style={{ width: 150, height: 150, background: "white" }}
                    />
                  </Col>
                  <Col md="9" sm="12">
                    <p className="mt-5 font-12 text-white font-weight-light">
                      {t("security_authentication_t_1")}
                    </p>
                  </Col>

                </Row>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  }
}

export default Security;
